
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import { Options, prop, Vue } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { CodReconcileController } from "@/app/ui/controllers/CodReconcileController";
import {
  CourierDetailPendingReconcile,
  CourierDetailApiRequest,
} from "@/domain/entities/CodReconcile";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";

class Props {
  modelValue = prop<boolean>({
    default: false,
    type: Boolean,
  });
  detailDataCourier = prop<any>({
    default: null,
  });
  filterDate = prop<string>({
    default: "",
    type: String,
  });
}

@Options({
  emits: ["update:modelValue", "deposit", "close"],
})
export default class PopupSetorHarianKurir extends Vue.with(Props) {
  mounted() {
    this.getDetailCourier();
  }

  changeValue() {
    this.$emit("update:modelValue", false);
  }

  close() {
    this.changeValue();
    this.$emit("close", false);
  }

  formatPriceRp(value: number) {
    return formatPriceRP(value);
  }

  formatPriceWithoutCurrency(value: number) {
    return formatPriceWithoutCurrency(value);
  }

  detailCourierPendingApproval: CourierDetailPendingReconcile[] = [];
  async getDetailCourier() {
    try {
      MainAppController.showLoading();
      this.detailCourierPendingApproval = await CodReconcileController.getDetailCourierPendingReconcile(
        new CourierDetailApiRequest({
          filterDate: this.$moment(this.filterDate).format("YYYY-MM-DD"),
          driverPhone: this.detailDataCourier.courierPhone,
        })
      );
      this.detailCourierPendingApproval.sort((a, b) => {
        return (
          new Date(a.dateGroup).getTime() - new Date(b.dateGroup).getTime()
        );
      });
    } catch (error) {
      const message = (error as any)?.response?.data?.message;
      MainAppController.showErrorMessage(
        typeof message === "string"
          ? new ErrorMessageEntities({
              title: "Gagal Mengambil Detail Kurir",
              message,
            })
          : parsingErrorResponse(error)
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  postCourierDailyDeposit(data: CourierDetailPendingReconcile) {
    this.$emit("deposit", data);
    this.changeValue();
  }
}
