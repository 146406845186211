import { injectable } from "tsyringe";
import { ReconcileCodApiRepository } from "@/app/infrastructures/repositories/api/ReconcileCodApiRepository";
import { ListPICShuttledRequest } from "@/domain/entities/ReconcileCod";
import { ListCourierPartnerCodDashboard } from "@/domain/entities/Dashboard";

@injectable()
export class ReconcileCodPresenter {
  private repository: ReconcileCodApiRepository;

  constructor(repository: ReconcileCodApiRepository) {
    this.repository = repository;
  }

  public getListPICShuttle(
    params: ListPICShuttledRequest
  ): Promise<ListCourierPartnerCodDashboard> {
    return this.repository.getListPICShuttle(params);
  }
}
