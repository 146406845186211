
import { Options, Vue } from "vue-class-component";
import ReportCourierV1 from "../../cod-delivery/cod-report-reconcile/tabs/report-courier.vue";
import ReportCourierV2 from "../../cod-delivery-v2/cod-report-reconcile/tabs/report-courier.vue";
@Options({
  components: {
    ReportCourierV1,
    ReportCourierV2,
  },
})
export default class ConfigReportCourier extends Vue {
  get configCodReconcile() {
    return "v1";
  }

  get renderedPage(): string {
    return this.configCodReconcile === "v2"
      ? "ReportCourierV2"
      : "ReportCourierV1";
  }
}
