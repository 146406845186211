
import { Options, Vue } from "vue-class-component";
import CodDeliveryV1 from "../../cod-delivery/cod-reconcile/index.vue";
import CodDeliveryV2 from "../../cod-delivery-v2/cod-reconcile/index.vue";
@Options({
  components: {
    CodDeliveryV1,
    CodDeliveryV2,
  },
})
export default class ConfigCodDelivery extends Vue {
  get configCodReconcile() {
    return "v2";
  }

  get renderedPage(): string {
    return this.configCodReconcile === "v2" ? "CodDeliveryV2" : "CodDeliveryV1";
  }
}
