import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "space-y-3" }
const _hoisted_2 = { class: "text-gray-lp-300 text-14px" }
const _hoisted_3 = { class: "flex flex-nowrap" }
const _hoisted_4 = { class: "flex items-end flex-nowrap gap-x-2" }
const _hoisted_5 = {
  style: {"font-size":"18px"},
  class: "font-semibold text-gray-lp-300"
}
const _hoisted_6 = { class: "text-12px text-red-lp-100" }
const _hoisted_7 = {
  key: 0,
  class: "text-14px font-semibold text-green-lp-400"
}
const _hoisted_8 = {
  key: 1,
  class: "grid grid-cols-2 gap-2"
}
const _hoisted_9 = { class: "text-gray-lp-300 text-14px font-semibold" }
const _hoisted_10 = {
  key: 0,
  class: "text-18px font-semibold text-green-lp-400"
}
const _hoisted_11 = { class: "flex items-end flex-nowrap gap-x-2" }
const _hoisted_12 = { class: "text-12px text-red-lp-100" }
const _hoisted_13 = {
  key: 0,
  class: "text-18px font-semibold text-green-lp-400"
}
const _hoisted_14 = { class: "flex items-end flex-nowrap gap-x-2" }
const _hoisted_15 = { class: "text-12px text-red-lp-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_skeleton = _resolveComponent("skeleton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: ["rounded-md p-2 flex max-w-max", `bg-${_ctx.backgroundColor}`]
    }, [
      _createVNode(_component_Icons, {
        name: _ctx.iconName,
        class: ["h-5", `text-${_ctx.iconColor}`]
      }, null, 8, ["name", "class"])
    ], 2),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.statusName) + " ", 1),
        _createVNode(_component_TooltipV2, { description: _ctx.toolTipDescription }, null, 8, ["description"])
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.statusSubName), 1)
    ]),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          (!_ctx.swapDisplay)
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                _createVNode("div", _hoisted_4, [
                  _createVNode("div", _hoisted_5, _toDisplayString(_ctx.sttDisplay) + " STT ", 1),
                  _createVNode("div", _hoisted_6, " (" + _toDisplayString(_ctx.percentageSttDisplay) + "%) ", 1)
                ]),
                (_ctx.totalAmountSttDisplay)
                  ? (_openBlock(), _createBlock("div", _hoisted_7, _toDisplayString(_ctx.totalAmountSttDisplay), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : (_ctx.detail.length)
              ? (_openBlock(), _createBlock("div", _hoisted_8, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.detail, (item) => {
                    return (_openBlock(), _createBlock("div", {
                      key: item.statusName,
                      class: "flex flex-col"
                    }, [
                      _createVNode("div", _hoisted_9, _toDisplayString(item.statusName), 1),
                      (item.totalAmountSttDisplay)
                        ? (_openBlock(), _createBlock("div", _hoisted_10, _toDisplayString(item.totalAmountSttDisplay), 1))
                        : _createCommentVNode("", true),
                      _createVNode("div", _hoisted_11, [
                        _createVNode("div", {
                          class: [
                `${item.totalAmountSttDisplay ? 'text-12px' : 'text-18px'}`
              , "font-semibold text-gray-lp-300"]
                        }, _toDisplayString(item.sttDisplay) + " STT ", 3),
                        _createVNode("div", _hoisted_12, " (" + _toDisplayString(item.percentageSttDisplay) + "%) ", 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
                  (_ctx.totalAmountSttDisplay)
                    ? (_openBlock(), _createBlock("div", _hoisted_13, _toDisplayString(_ctx.totalAmountSttDisplay), 1))
                    : _createCommentVNode("", true),
                  _createVNode("div", _hoisted_14, [
                    _createVNode("div", {
                      class: [`${_ctx.totalAmountSttDisplay ? 'text-12px' : 'text-18px'}`, "font-semibold text-gray-lp-300"]
                    }, _toDisplayString(_ctx.sttDisplay) + " STT ", 3),
                    _createVNode("div", _hoisted_15, " (" + _toDisplayString(_ctx.percentageSttDisplay) + "%) ", 1)
                  ])
                ], 64))
        ], 64))
      : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_skeleton, { height: "1rem" }),
          _createVNode(_component_skeleton, { height: "1rem" })
        ], 64))
  ]))
}