
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatInputMoneyWithDecimal from "@/app/infrastructures/misc/common-library/FormatInputMoneyWithDecimal";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { Options, prop, Vue } from "vue-class-component";
import { CourierDailyDepositRequest } from "@/data/payload/api/DashboardRequests";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import {
  ErrorMessageEntities,
  ModalMessageEntities,
} from "@/domain/entities/MainApp";
import { CodReconcileController } from "@/app/ui/controllers/CodReconcileController";
import {
  CourierDetail,
  CourierDetailApiRequest,
  CourierSummary,
  DepositAmount,
} from "@/domain/entities/CodReconcile";
import whatsapp from "@/app/infrastructures/misc/common-library/Whatsapp";
import { CourierPartnerCodDashboard } from "@/domain/entities/Dashboard";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";

class Props {
  modelValue = prop<boolean>({
    default: false,
    type: Boolean,
  });
  detailDataCourier = prop<any>({
    default: null,
  });
  type = prop<string>({
    default: "courier",
    type: String,
  });
  filterDate = prop<string>({
    default: "",
    type: String,
  });
}

@Options({
  emits: ["update:modelValue", "close"],
})
export default class PopupSetorHarianKurir extends Vue.with(Props) {
  sttNumber = "";
  isSearchSTT = false;
  isEmptySTT = false;
  expandSttPending = false;
  refs = null as any;

  mounted() {
    this.form.date = new Date(this.filterDate);
    this.getDetailCourierFailed();
    this.getDetailCourierSuccess();
    this.getCourierSummaryDetail();
    this.refs = this.$refs;
  }
  closeDialog(success = false) {
    this.$emit("update:modelValue", false);
    this.$emit("close", success);
  }
  resetForm() {
    this.form.deposit = "";
    this.isErrorDeposit = false;
  }
  onExpandSttPending() {
    if (this.courierSummary.codAmountDeposit?.pending) {
      this.expandSttPending = !this.expandSttPending;
    }
  }

  get isDisabled() {
    return (
      (this.courierSummary.codAmountDeposit?.pending && !this.form.deposit) ||
      (!this.courierSummary.codAmountDeposit?.pending &&
        !this.dataSelected.length) ||
      this.isErrorDeposit
    );
  }

  form = {
    date: DashboardController.filterDateCodDashboard,
    deposit: "",
  };
  maxDate = new Date();
  formatMoney(value: string) {
    return formatInputMoneyWithDecimal(value, 10);
  }
  formatNumber(value: string) {
    return value.replace(/[\D]+/g, "");
  }
  formatPriceRp(value: number) {
    return formatPriceRP(value);
  }
  formatPriceWithoutCurrency(value: number) {
    return formatPriceWithoutCurrency(value);
  }
  isErrorDeposit = false;
  validationInput(value: string, type: string) {
    const inputValue = value.toString();
    const inputDeposit = Number(
      inputValue.replace(/[.]/g, "").replace(/[,]/g, ".")
    );
    if (type === "deposit") {
      this.isErrorDeposit =
        !value ||
        (value !== "0" &&
          inputDeposit !== this.courierSummary.codAmountDeposit?.pending);
    }
    return false;
  }

  get depositValue() {
    return !this.form.deposit ||
      this.form.deposit === "0" ||
      this.isErrorDeposit
      ? 0
      : this.courierSummary.codAmountDeposit?.pending;
  }
  async postCourierDailyDeposit() {
    try {
      MainAppController.showLoading();
      const response = await DashboardController.postCourierDailyDepositV3({
        type: this.type,
        payload: new CourierDailyDepositRequest({
          driverPhone: this.detailDataCourier.courierPhone.replaceAll("+", ""),
          picPhone: this.detailDataCourier.courierPhone.replaceAll("+", ""),
          filterDate: this.$moment(this.form.date).format("YYYY-MM-DD"),
          totalAmount: this.depositValue,
          stt: this.dataSelected,
        }),
      });

      if (response.success) {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Setor harian berhasil!",
            message: `Uang sebesar ${this.formatPriceRp(this.depositValue)} & ${
              this.dataSelected.length ? `${this.dataSelected.length} STT` : ""
            } berhasil disetor.`,
            textSuccess: "OK",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              this.closeDialog(true);
            },
          })
        );
      }
    } catch (error) {
      const errorMessage = (error as any)?.response?.data?.message?.id;
      MainAppController.showErrorMessage(
        errorMessage
          ? new ErrorMessageEntities({
              type: "client-error",
              title: "Setor harian gagal!",
              message: errorMessage,
              buttonSuccessText: "OK",
            })
          : parsingErrorResponse(
              error,
              "Setor harian gagal!",
              this.postCourierDailyDeposit
            )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  courierSummary = new CourierSummary();
  get courierSummaryCount(): CourierPartnerCodDashboard {
    const dex = new DepositAmount(
      this.courierSummary.sttDeposit.find(
        (e: DepositAmount) => e.status.toLowerCase() === "dex"
      )
    );

    const sttDex = dex.pending;
    const sttDexPieces = dex.pendingPieces;

    const codrej = new DepositAmount(
      this.courierSummary.sttDeposit.find(
        (e: DepositAmount) => e.status.toLowerCase() === "codrej"
      )
    );
    const sttCodrej = codrej.pending;
    const sttCodrejPieces = codrej.pendingPieces;

    return new CourierPartnerCodDashboard({
      totalSttDex: sttDex,
      totalSttDexPieces: sttDexPieces,
      totalSttCodrej: sttCodrej,
      totalSttCodrejPieces: sttCodrejPieces,
      totalStt: sttDex + sttCodrej,
      totalSttPieces: sttDexPieces + sttCodrejPieces,
    });
  }
  async getCourierSummaryDetail(date?: Date) {
    try {
      MainAppController.showLoading();
      const res: CourierSummary = await CodReconcileController.getCourierSummaryDetailV2(
        new CourierDetailApiRequest({
          type: "DRIVER",
          filterDate: this.$moment(date || this.form.date).format("YYYY-MM-DD"),
          driverPhone: this.detailDataCourier.courierPhone,
        })
      );

      this.courierSummary = res;
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Gagal Mendapatkan Summary!",
          this.getCourierSummaryDetail
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  async getDetailCourierFailed() {
    await CodReconcileController.getCourierDetail(
      new CourierDetailApiRequest({
        type: this.type,
        filterDate: this.$moment(this.form.date).format("YYYY-MM-DD"),
        driverPhone: this.detailDataCourier.courierPhone,
        picPhone: this.detailDataCourier.courierPhone,
        status: "failed",
      })
    );
    this.isSearchSTT = false;
    this.isEmptySTT = false;
  }

  detailCourierSuccess: CourierDetail[] = [];
  async getDetailCourierSuccess() {
    try {
      MainAppController.showLoading();
      const res: CourierDetail[] = await CodReconcileController.getCourierDetailV2(
        new CourierDetailApiRequest({
          type: this.type,
          filterDate: this.$moment(this.form.date).format("YYYY-MM-DD"),
          driverPhone: this.detailDataCourier.courierPhone,
          status: "success",
        })
      );
      this.detailCourierSuccess = res.filter(
        (e: CourierDetail) =>
          e.sttStatus === "POD" &&
          e.sttCollectionStatus.toLowerCase() === "belum setor"
      );
    } catch (error) {
      const message = (error as any)?.response?.data?.message;
      MainAppController.showErrorMessage(
        typeof message === "string"
          ? new ErrorMessageEntities({
              title: "Gagal Mengambil Detail Kurir",
              message,
            })
          : parsingErrorResponse(error)
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  dataSelected: string[] = [];
  selectData(data: CourierDetail[]) {
    this.dataSelected = data.map((e: CourierDetail) => e.sttNo);
  }

  isScanning = false;
  lastScanChar = "";

  onScanSttNumber(val: string, self = this) {
    MainAppController.setSnackbar(false);
    const splitVal = val.split("#")[0];
    const refs: any = self.refs.detailCourierTable;
    const selectedValue = self.detailCourier.find((item: any) => {
      return item.sttNo === self.sttNumber;
    });

    self.sttNumber = splitVal;
    self.isSearchSTT = true;
    if (selectedValue) {
      if (!self.dataSelected.includes(splitVal)) {
        refs.selectedCheckbox.push(selectedValue);
        MainAppController.setSnackbarMessage("Berhasil Scan");
        MainAppController.setSnackbarPositivity(true);
        MainAppController.setSnackbarTimeout(1500);
        MainAppController.setSnackbar(true);
      } else {
        MainAppController.setSnackbarMessage("STT sudah dipilih");
        MainAppController.setSnackbarPositivity(false);
        MainAppController.setSnackbarTimeout(1500);
        MainAppController.setSnackbar(true);
      }
      self.isEmptySTT = false;
    } else {
      self.isEmptySTT = true;
    }
    if (!self.isEmptySTT) {
      self.sttNumber = "";
    }
    this.isScanning = false;
  }

  onFocusStt(event: any) {
    const val = event.target.value?.toUpperCase();
    const inputType = event.inputType;
    const inputEl = document.getElementById(
      "cod-reconcile-scan"
    ) as HTMLInputElement;
    if (!this.isScanning) {
      this.sttNumber = "";
      inputEl.value = "";
      this.lastScanChar = val[val.length - 1] || "";
      this.isScanning = true;
      if (this.isEmptySTT) {
        if (inputType === "deleteContentBackward") {
          this.sttNumber = "";
        } else {
          if (inputType === "insertFromPaste") {
            let splitVal = val.split(" ");
            splitVal = splitVal[splitVal.length - 1];
            this.sttNumber = splitVal?.toUpperCase();
          } else {
            this.sttNumber = this.lastScanChar?.toUpperCase();
          }
        }
      } else {
        this.sttNumber = val?.toUpperCase();
      }
    } else {
      this.sttNumber = val?.toUpperCase();
    }
    if (this.isEmptySTT) {
      this.isEmptySTT = false;
    }
  }

  // table
  get detailCourier() {
    return CodReconcileController.courierDetailList.data;
  }

  get isLoading() {
    return CodReconcileController.isLoading;
  }

  get isEmptyList(): boolean {
    return !this.detailCourier.length || this.isEmptySTT;
  }

  get emptyIcon(): string {
    return this.isSearchSTT ? "noData_filter" : "noData_empty";
  }

  get emptyTitle(): string {
    return this.isSearchSTT ? "Nomor STT tidak ditemukan" : "";
  }

  get emptyMessage(): string {
    return this.isSearchSTT
      ? "Pastikan nomor STT sudah sesuai, cek & ubah lagi."
      : "Belum ada STT yang harus di setor";
  }

  get columns() {
    return [
      {
        name: "No",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (item: CourierDetail, index: any) => {
          return `<div class="overflow-ellipsis text-left text-14px">${index +
            1}</div>`;
        },
      },
      {
        name: "No. STT",
        styleHead: "w-44 text-left whitespace-nowrap",
        render: (item: CourierDetail) => {
          return `<div class="text-left text-14px">${item.sttNo}</div>`;
        },
      },
      {
        name: "Status terakhir",
        styleHead: "w-44 text-left whitespace-nowrap",
        render: (item: CourierDetail) => {
          return `<div class="overflow-ellipsis text-left break-all text-14px">${item.sttStatus}</div>`;
        },
      },
      {
        name: "Alasan",
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: CourierDetail) => {
          return `<div class="overflow-ellipsis text-left break-all text-14px">${item.sttReason}</div>`;
        },
      },
      {
        name: "Nama penerima",
        key: "actionable_column",
        styleHead: "w-52 text-left whitespace-nowrap",
        render: (item: CourierDetail) => {
          return `<div class="overflow-ellipsis text-left break-all text-14px mr-4">${item.sttRecipientName}</div>
            <div class="text-black-lp-300 font-semibold text-14px mr-4">${item.sttRecipientPhone}</div>`;
        },
        showButton: () => true,
        styleCustom: "items-end",
        icon: "whatsapp",
        iconStyle: "pt-4",
        disableButtonValue: () => false,
        onClickValue: this.onCLickWhatsapp,
      },
    ];
  }

  // whatsapp
  onCLickWhatsapp(item: CourierDetail) {
    whatsapp(item.sttRecipientPhone);
  }
}
