
import { Vue, Options } from "vue-class-component";
import ListCourierPartnerSection from "@/app/ui/views/cod-delivery/components/list-courier-partner-section.vue";

@Options({
  components: {
    ListCourierPartnerSection
  }
})
export default class CodCourier extends Vue {}
