
import { Vue, Options } from "vue-class-component";
import ListCourierPartnerSectionV2 from "@/app/ui/views/cod-delivery-v2/components/list-courier-partner-section-v2.vue";

@Options({
  components: {
    ListCourierPartnerSectionV2
  }
})
export default class CodCourier extends Vue {}
