import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "py-8 space-y-6 overflow-auto" }
const _hoisted_2 = { class: "flex flex-col md:flex-row gap-x-4 space-y-2 md:space-y-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_OverallSummary = _resolveComponent("OverallSummary")!
  const _component_WidgetDownload = _resolveComponent("WidgetDownload")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DatePicker, {
          onSelected: _ctx.onSelectDatePicker,
          modelValue: _ctx.filterDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterDate = $event)),
          "max-date": new Date(),
          class: "w-full md:w-1/2 lg:w-3/12"
        }, null, 8, ["onSelected", "modelValue", "max-date"]),
        _createVNode(_component_LpButton, {
          textColor: "white",
          title: "Download Detail",
          iconLeft: "download-new",
          iconV2: "",
          small: "",
          customClass: "w-full md:w-50",
          onClick: _ctx.downloadSummary
        }, null, 8, ["onClick"])
      ]),
      (_ctx.reRenderComponent)
        ? (_openBlock(), _createBlock(_component_OverallSummary, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_WidgetDownload)
  ], 64))
}