
import { AccountController } from "@/app/ui/controllers/AccountController";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { ListCourierPartnerCodDashboard } from "@/domain/entities/Dashboard";
import { FlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";
import { Vue } from "vue-class-component";
export default class CodReconcile extends Vue {
  created() {
    this.activeMenu = this.$route.name?.toString() ?? "";
    if (
      !FlagsPermissionCodReconcile.permission_cod_reconcile_pic_enable.isEnabled()
    ) {
      this.tabs.pop();
    }
  }

  activeMenu = "";
  get tabs() {
    const isConsole = AccountController.accountData.isConsoleAccount;
    return [
      { value: "cod-courier", title: isConsole ? "Partner" : "Kurir" },
      { value: "cod-pic-shuttle", title: "PIC Shuttle" }
    ];
  }

  onChangeTabs(value: string) {
    if(value !== this.activeMenu) this.resetData();
    this.activeMenu = value;
    this.$router.push({
      name: value
    });
  }

  resetData() {
    DashboardController.setSearchListCourierPartnerCodDashboard("");
    DashboardController.setFilterDateCodDashboard(new Date());
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
  }
}
