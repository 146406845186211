
import { Options, Vue } from "vue-class-component";
import ReportCodPicShuttleV1 from "../../cod-delivery/cod-report-reconcile/tabs/report-pic-shuttle.vue";
import ReportCodPicShuttleV2 from "../../cod-delivery-v2/cod-report-reconcile/tabs/report-pic-shuttle.vue";
@Options({
  components: {
    ReportCodPicShuttleV1,
    ReportCodPicShuttleV2,
  },
})
export default class ConfigReportCourier extends Vue {
  get configCodReconcile() {
    return "v1";
  }

  get renderedPage(): string {
    return this.configCodReconcile === "v2"
      ? "ReportCodPicShuttleV2"
      : "ReportCodPicShuttleV1";
  }
}
