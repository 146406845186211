import { ReconcileCodPresenter } from "@/app/ui/presenters/ReconcileCodPresenter";
import { ReconcileCodMapper } from "@/data/persistences/mappers/ReconcileCodMapper";
import { container } from "tsyringe";
import { ReconcileCodEndpoints } from "../../endpoints/hydra/ReconcileCodEndpoints";
import { ReconcileCodApiRepository } from "../../repositories/api/ReconcileCodApiRepository";
import ApiService from "../../services/ApiService";

export class ReconcileCodComponent {
    public static init() {
        container.register<ReconcileCodApiRepository>(ReconcileCodApiRepository, {
          useFactory: d => {
            return new ReconcileCodApiRepository(
              d.resolve(ApiService),
              d.resolve(ReconcileCodMapper),
              d.resolve(ReconcileCodEndpoints)
            );
          }
        });
        container.register<ReconcileCodMapper>(ReconcileCodMapper, {
          useClass: ReconcileCodMapper
        });
        container.register<ReconcileCodPresenter>(ReconcileCodPresenter, {
          useFactory: d => {
            return new ReconcileCodPresenter(d.resolve(ReconcileCodApiRepository));
          }
        });
    }
}
