
import { FlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import Refresh from "@/app/ui/components/icons/modules/refresh.vue";
import { Vue, Options } from "vue-class-component";

@Options({
  components: {
    Refresh,
  },
})

export default class CodReportReconcile extends Vue {
  created() {
    this.getLastSyncDataDashboard();
    this.activeMenu = this.$route.name?.toString() ?? "";
    if (
      !FlagsPermissionCodReconcile.permission_cod_reconcile_pic_enable.isEnabled()
    ) {
      this.tabs.pop();
    }
  }

  async getLastSyncDataDashboard() {
    const response = await DashboardController.getLastSyncCOD();
    const lastSyncCODDashboard = !response.lastSyncDate
      ? ""
      : this.$moment(response.lastSyncDate).format("DD MMMM YYYY, HH:mm");
    DashboardController.setLastSycnCODDashboard(lastSyncCODDashboard);
  }

  activeMenu = "";
  tabs = [
    { value: "report-courier", title: "Kurir" },
    { value: "report-pic-shuttle", title: "PIC Shuttle" },
  ];

  onChangeTabs(value: string) {
    this.activeMenu = value;
    this.$router.push({
      name: value,
    });
  }

  get lastSycnCODDashboard() {
    return DashboardController.lastSyncCODDashboard;
  }
}
