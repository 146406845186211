
import { Options, Vue } from "vue-class-component";
import Accordion from "@/app/ui/components/accordion/index.vue";
import {
  formatPrice,
  formatPriceRP,
  whatsapp
} from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DetailCourierPerStatusCodDashboardData } from "@/domain/entities/Dashboard";
import PopupSetorHarian from "../components/popup-setor-harian-kurir.vue";
import { FlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";

@Options({
  components: {
    Accordion,
    PopupSetorHarian
  }
})
export default class Detail extends Vue {
  mounted() {
    DashboardController.setPhoneNumberCourier(this.driverPhone);
    DashboardController.setFilterDateCodDashboard(this.date);
    DashboardController.setSttDataCourierData([]);
    DashboardController.getDetailCourierPartnerCodDashboard({
      type: this.type
    });
    this.fetchSummaryCodDashboard();
  }
  get detailCourier() {
    return DashboardController.detailCourierPartnerCodDashboard;
  }
  goBack() {
    this.$router.push(this.$route.meta.before as string);
  }
  downloadDeposit() {
    // to do download deposit
    this.popUpSetorHarian(true, this.detailCourier);
  }
  downloadDetail() {
    // to do download detail
    DashboardController.getCourierDetailDownload({
      driverPhone: this.driverPhone,
      isPic: this.type !== "courier"
    });
  }

  get detail() {
    const detail = {
      title: this.type === "courier" ? "Detail Kurir" : "Detail PIC Shuttle",
      name: this.type === "courier" ? "Nama Kurir" : "Nama PIC Shuttle",
      setor:
        this.type === "courier"
          ? "Setor Harian Kurir"
          : "Setor Harian PIC Shuttle"
    };

    return detail;
  }

  get type() {
    return this.$route.query["type"];
  }
  get driverPhone(): string {
    return this.$route.query["driver-phone"] as string;
  }
  get date(): Date {
    return new Date(this.$route.query["date"] as string);
  }
  get showButtonDeposit() {
    return this.type === "courier"
      ? FlagsPermissionCodReconcile.permission_cod_reconcile_deposit.isEnabled()
      : FlagsPermissionCodReconcile.permission_cod_reconcile_pic_deposit.isEnabled();
  }

  listOfDetailPerStatus: DetailCourierPerStatusCodDashboardData[] = [
    new DetailCourierPerStatusCodDashboardData({
      status: "success",
      loading: true
    }),
    new DetailCourierPerStatusCodDashboardData({
      status: "failed",
      loading: true
    }),
    new DetailCourierPerStatusCodDashboardData({
      status: "onprogress",
      loading: true
    })
  ];
  async fetchSummaryCodDashboard() {
    let delay = 0;
    const delayIncrement = 250;
    this.listOfDetailPerStatus.forEach(
      async (item: DetailCourierPerStatusCodDashboardData) => {
        delay += delayIncrement;
        return new Promise(resolve => {
          setTimeout(resolve, delay);
        }).then(() => {
          return DashboardController.getDetailCourierPerStatusCodDashboardData({
            type: this.$route.query["type"],
            sttStatus: item.status
          });
        });
      }
    );
  }
  get successfulDelivery() {
    return DashboardController.sttDataCourier.filter(
      (item: DetailCourierPerStatusCodDashboardData) => {
        return item.sttStatus === "POD";
      }
    );
  }

  get failedDelivery() {
    return DashboardController.sttDataCourier.filter(
      (item: DetailCourierPerStatusCodDashboardData) => {
        return item.sttStatus === "CODREJ" || item.sttStatus === "DEX";
      }
    );
  }

  get onDelivery() {
    return DashboardController.sttDataCourier.filter(
      (item: DetailCourierPerStatusCodDashboardData) => {
        return item.sttStatus === "DEL";
      }
    );
  }

  getCodAmount(item: any) {
    return formatPriceRP(item.sttCodAmount);
  }

  columnData(params: {
    name: string;
    key: string;
    styleHead?: string;
    styleBody?: string;
  }) {
    const { name, key, styleHead, styleBody } = params;
    return {
      name,
      styleHead: `${styleHead} text-left whitespace-no-wrap`,
      styleBody: `${styleBody} text-center`,
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item[key]}</div>`;
      }
    };
  }
  get rowsData() {
    return [
      this.columnData({
        name: "No. STT",
        styleHead: "w-40",
        key: "sttNo"
      }),
      this.columnData({
        name: "Status STT",
        styleHead: "w-60",
        key: "sttStatus"
      }),
      this.columnData({
        name: "Tipe Pembayaran COD",
        styleHead: "w-50",
        key: "sttPaymentMethod"
      }),
      this.columnData({
        name: "Status Collection",
        styleHead: "w-50",
        key: "sttCollectionStatus"
      })
    ];
  }
  get rows() {
    return [
      {
        name: "No.",
        styleHead: "w-20 text-left whitespace-no-wrap",
        styleBody: "text-center",
        render: (item: any, index: any) => {
          return `<div class='text-left text-gray-lp-300'>${index + 1}</div>`;
        }
      },
      ...this.rowsData,
      {
        name: "Nilai COD",
        styleHead: "w-50 text-left whitespace-no-wrap",
        styleBody: "text-center",
        render: (item: any) => {
          return this.renderCodAmount(item);
        }
      }
    ];
  }
  get columnSuccessful() {
    const courierName: any = {
      name: "Nama Kurir",
      styleHead: "w-50 text-left whitespace-nowrap",
      render: (item: any) => {
        return (
          `<div class='text-black-lp-300'>${item.sttDriverName}</div>` +
          `<div class='text-black-lp-300'>${item.sttDriverPhone}</div>`
        );
      },
      showButton: (item: any) => {
        return item.sttDriverPhone;
      },
      styleCustom: "items-end"
    };

    if (this.type === "pic") {
      return this.rows.splice(2, 0, courierName);
    }

    return this.rows;
  }

  get rowsFailed() {
    return [
      {
        name: "No.",
        styleHead: "w-20 text-left whitespace-no-wrap",
        styleBody: "text-center",
        render: (item: any, index: any) => {
          return `<div class='text-left text-gray-lp-300'>${index + 1}</div>`;
        }
      },
      this.columnData({
        name: "No. STT",
        styleHead: "w-40",
        key: "sttNo"
      }),
      this.columnData({
        name: "Status STT",
        styleHead: "w-28",
        key: "sttStatus"
      }),
      this.columnData({
        name: "Status Collection",
        styleHead: "w-36",
        key: "sttCollectionStatus"
      }),
      this.columnData({
        name: "Alasan",
        styleHead: "w-50",
        key: "sttReason"
      }),
      this.columnData({
        name: "Nama Penerima",
        styleHead: "w-50",
        key: "sttRecipientName"
      }),
      this.columnData({
        name: "Nomor Telepon Penerima",
        styleHead: "w-50",
        key: "sttRecipientPhone"
      })
    ];
  }
  get columnFailed() {
    const courierName: any = {
      name: "Nama Kurir",
      styleHead: "w-50 text-left whitespace-nowrap",
      render: (item: any) => {
        return (
          `<div class='text-black-lp-300'>${item.sttDriverName}</div>` +
          `<div class='text-black-lp-300'>${item.sttDriverPhone}</div>`
        );
      },
      showButton: (item: any) => {
        return item.sttDriverPhone;
      },
      styleCustom: "items-end"
    };

    if (this.type === "pic") {
      this.rowsFailed.splice(2, 0, courierName);
    }

    return this.rowsFailed;
  }

  onCLickWhatsapp() {
    return true;
  }

  columnOnDelivery = [
    {
      name: "No.",
      styleHead: "w-20 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any, index: any) => {
        return `<div class='text-left text-gray-lp-300'>${index + 1}</div>`;
      }
    },
    {
      name: "No.STT",
      styleHead: "w-50 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item.sttNo}</div>`;
      }
    },
    {
      name: "Status STT",
      styleHead: "w-28 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item.sttStatus}</div>`;
      }
    },
    {
      name: "Nilai COD",
      styleHead: "w-50 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any) => {
        return this.renderCodAmount(item);
      }
    },
    {
      name: "Nama Penerima",
      styleHead: "w-50 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item.sttRecipientName}</div>`;
      }
    },
    {
      name: "Nomor Telepon Penerima",
      styleHead: "w-50 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item.sttRecipientPhone}</div>`;
      }
    }
  ];

  renderCodAmount(item: any) {
    return `<div class='text-left text-gray-lp-300'>${this.getCodAmount(
      item
    )}</div>`;
  }

  // table
  get firstRowHeaderColumns() {
    const rows = [
      {
        name: "Performa Pengiriman",
        colSpan: 2,
        styleHead:
          "w-500px text-center whitespace-no-wrap border-horizontal-separation"
      },
      {
        name: "Dalam<br>Pengiriman",
        styleHead: "w-48 text-center whitespace-no-wrap"
      },
      {
        name: "Pengiriman Berhasil",
        colSpan: 3,
        styleHead:
          "w-500px text-left whitespace-no-wrap border-horizontal-separation"
      },
      {
        name: "Pengiriman Gagal",
        colSpan: 4,
        styleHead:
          "w-500px text-left whitespace-no-wrap border-horizontal-separation"
      }
    ];

    return this.type === "courier" ? rows : rows.slice(2);
  }
  get tooltipHeaderBelumSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) tetapi belum disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get tooltipHeaderSudahSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) dan telah disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get secondRowHeaderColumns() {
    const rows = [
      {
        name: "Sehari",
        styleHead:
          "w-40 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "<ol style='list-style-type: decimal;'>" +
            "<li>Total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari yang sama.</li>" +
            "<li>Persentasi total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari yang sama.</li>" +
            "</ol>" +
            "</div>"
          );
        }
      },
      {
        name: "Lewat Sehari",
        styleHead: "w-42 text-left whitespace-no-wrap",
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "<ol style='list-style-type: decimal;'>" +
            "<li>Total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari sebelumnya.</li>" +
            "<li>Persentasi total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari sebelumnya.</li>" +
            "</ol>" +
            "</div>"
          );
        }
      },
      {
        name: "Dalam Pengiriman",
        hide: true,
        styleHead: "w-16 text-left whitespace-no-wrap",
        render: () => {
          return `${this.detailCourier.totalStt} STT`;
        }
      },
      {
        name: "Belum Setor",
        styleHead:
          "w-32 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        render: () => {
          return `${formatPriceRP(this.detailCourier.totalOutstanding)}`;
        },
        tooltipHeader: () => {
          return this.tooltipHeaderBelumSetor;
        }
      },
      ...this.secondRowHeaderColumns2
    ];

    return this.type === "courier" ? rows : rows.slice(3);
  }
  get secondRowHeaderColumns2() {
    return [
      {
        name: "Sudah Setor",
        colSpan: 2,
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: () => {
          return `${formatPriceRP(this.detailCourier.totalCollected)}`;
        },
        tooltipHeader: () => {
          return this.tooltipHeaderSudahSetor;
        }
      },
      {
        name: "CODREJ",
        colSpan: 2,
        styleHead:
          "w-32 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        render: () => {
          return `${this.detailCourier.totalSttCodrejCollected} STT`;
        },
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "Total STT COD yang gagal dikirim (CODREJ) oleh kurir." +
            "</div>"
          );
        }
      },
      {
        name: "DEX",
        colSpan: 2,
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: () => {
          return `${this.detailCourier.totalSttDexCollected} STT`;
        },
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "Total STT COD yang gagal dikirim (DEX) oleh kurir." +
            "</div>"
          );
        }
      }
    ];
  }

  get columns() {
    const rows = [
      {
        name: "Sehari",
        hide: true,
        styleHead: "w-40 text-center whitespace-no-wrap",
        render: () => {
          const performance = this.detailCourier.performanceSameDay.split("/");
          return (
            `<div class='text-black-lp-300 text-center font-normal'>${performance[0] ??
              ""}<span class='font-semibold'>/${performance[1] ??
              ""}</span></div>` +
            `<div class='text-12px text-center text-gray-lp-800'>(${Number(
              this.detailCourier.percentageSameDay
            ).toFixed(2)}%)</div>`
          );
        }
      },
      {
        name: "Lewat Sehari",
        hide: true,
        styleHead: "w-40 text-center whitespace-no-wrap",
        render: () => {
          const performance = this.detailCourier.performanceDifferentDay.split(
            "/"
          );
          return (
            `<div class='text-black-lp-300 text-center font-normal'>${performance[0] ??
              ""}<span class='font-semibold'>/${performance[1] ??
              ""}</span></div>` +
            `<div class='text-12px text-center text-gray-lp-800'>(${Number(
              this.detailCourier.percentageDifferentDay
            ).toFixed(2)}%)</div>`
          );
        }
      },
      {
        name: "Dalam Pengiriman",
        hide: true,
        styleHead: "w-16 text-left whitespace-no-wrap",
        render: () => {
          return `${this.detailCourier.totalSttDel} STT`;
        }
      },
      {
        name: "Belum Setor",
        hide: true,
        styleHead: "w-20 text-left whitespace-no-wrap",
        render: () => {
          return `${formatPriceRP(this.detailCourier.totalOutstanding)}`;
        },
        tooltipHeader: () => {
          return this.tooltipHeaderBelumSetor;
        }
      },
      ...this.columns2
    ];

    return this.type === "courier" ? rows : rows.splice(3);
  }
  get columns2() {
    return [
      {
        name: "QRIS",
        styleHead:
          "w-32 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        render: () => {
          return `${formatPriceRP(this.detailCourier.totalQrisCollected)}`;
        }
      },
      {
        name: "Tunai",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: () => {
          return `${formatPriceRP(this.detailCourier.totalCollected)}`;
        }
      },
      {
        name: "Belum Setor",
        styleHead:
          "w-24 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        render: () => {
          return `${this.detailCourier.totalSttCodrejOutstanding} STT`;
        }
      },
      {
        name: "Sudah Setor",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: () => {
          return `${this.detailCourier.totalSttCodrejCollected} STT`;
        }
      },
      {
        name: "Belum Setor",
        styleHead:
          "w-24 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        render: () => {
          return `${this.detailCourier.totalSttDexOutstanding} STT`;
        }
      },
      {
        name: "Sudah Setor",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: () => {
          return `${this.detailCourier.totalSttDexCollected} STT`;
        }
      }
    ];
  }
  // data table
  get dataCourierPartner() {
    return [
      {
        performance: 1,
        onProccess: 2
      }
    ];
  }

  onClickWhatsapp() {
    const templateMessage =
      `Halo ${this.detailCourier.courierName}\n` +
      `Kami menginformasikan ada STT COD perlu diselesaikan proses secepatnya:\n` +
      `${this.detailCourier.totalSttDel} STT COD masih proses pengiriman\n` +
      `${this.detailCourier.totalSttDexCollected +
        this.detailCourier
          .totalSttCodrejCollected} STT COD harus dikembalikan ke POS\n` +
      `${formatPrice(
        this.detailCourier.totalOutstanding
      )} Dana harus disetor ke POS\n` +
      `Harap langsung memproses pengiriman STT dan lakukan penyetoran
       Terima kasih`;
    whatsapp(this.detailCourier.courierPhone, templateMessage);
  }

  popupSetorHarianModelValue = false;
  detailDataCourier = {
    courierName: "",
    phoneNumber: "",
    depositMoney: 0,
    sttCodrej: 0,
    sttDex: 0
  };
  popUpSetorHarian(bool: boolean, data: any) {
    this.popupSetorHarianModelValue = bool;
    this.detailDataCourier.courierName = data.courierName;
    this.detailDataCourier.phoneNumber = data.courierPhone;
    this.detailDataCourier.depositMoney = data.totalOutstanding;
    this.detailDataCourier.sttCodrej = data.totalSttCodrejOutstanding;
    this.detailDataCourier.sttDex = data.totalSttDexOutstanding;
  }
}
