import { VuexModule, Module, Action, Mutation, getModule } from "vuex-module-decorators";
import { ReconcileCodComponent } from "@/app/infrastructures/dependencies/modules/ReconcileCodComponent";
import store from "@/store";
import { ReconcileCodPresenter } from "@/app/ui/presenters/ReconcileCodPresenter";
import { container } from "tsyringe";
import { ListPICShuttledRequest } from "@/domain/entities/ReconcileCod";
ReconcileCodComponent.init();

@Module({ namespaced: true, dynamic: true, store, name: "reconcile-cod" })
class ReconcileCodStore extends VuexModule {
  @Action
  public getListPICShuttle(params: ListPICShuttledRequest) {
    const presenter = container.resolve(ReconcileCodPresenter);
    return presenter.getListPICShuttle(params);
  }
}

export const ReconcileCodController = getModule(ReconcileCodStore);

