
import { Vue, Options } from "vue-class-component";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import OverallSummarySection from "./components/overall-summary-section.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { DownloadSummaryCodRequest } from "@/data/payload/api/DashboardRequests";

@Options({
  components: {
    OverallSummarySection
  }
})
export default class CodDashboard extends Vue {
  created() {
    DashboardController.setFilterDateCodDashboard(new Date());
  }
  get filterDate() {
    return DashboardController.filterDateCodDashboard;
  }
  set filterDate(newValue: Date) {
    DashboardController.setFilterDateCodDashboard(newValue);
  }
  reRenderComponent = true;
  onSelectDatePicker() {
    this.reRenderComponent = false;
    this.$nextTick(() => {
      this.reRenderComponent = true;
    });
  }

  downloadSummary() {
    const filter = new DownloadSummaryCodRequest({
      filterDate: this.$moment(this.filterDate).format("YYYY-MM-DD"),
      cityCode: this.accountType === "pos" ? "" : this.cityCodes
    });

    DashboardController.getSummaryDownload({
      type: this.accountType === "pos" ? "pos" : "consol",
      filter: filter
    });
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get accountType() {
    return this.dataProfile.account_type_detail.type;
  }

  get cityCodes() {
    const cities = this.dataProfile.account_location_list?.map(
      item => item.city_code
    );
    return cities?.join(",");
  }
}
