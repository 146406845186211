
import { Vue, Options, prop } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  formatDateNumber,
  formatPrice,
  whatsapp
} from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import {
  CourierPartnerCodDashboard,
  ListCourierPartnerCodDashboard
} from "@/domain/entities/Dashboard";
import PopupSetorHarian from "@/app/ui/views/cod-delivery/components/popup-setor-harian-kurir.vue";
import { ReconcileCodController } from "@/app/ui/controllers/ReconcileCodController";
import { ListPICShuttledRequest } from "@/domain/entities/ReconcileCod";
import moment from "moment";
import { FlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";
import { QueryParamsEntities2 } from "@/domain/entities/MainApp";
import { DownloadSummaryCodRequest } from "@/data/payload/api/DashboardRequests";

class Props {
  isPicShuttle = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  components: {
    PopupSetorHarian
  }
})
export default class ListCourierPartnerSection extends Vue.with(Props) {
  mounted() {
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.searchValue = "";
    this.filterDate = new Date();
    this.fetchCourierPartner();
  }
  get listType() {
    if (this.isPicShuttle) {
      return "pic-shuttle";
    }
    return "courier";
  }
  get actorName() {
    if (this.isPicShuttle) {
      return "PIC Shuttle";
    }
    return "Kurir";
  }
  // account type
  get dataProfile() {
    return AccountController.accountData;
  }
  get accountType() {
    return this.dataProfile.account_type;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  get isPos() {
    return AccountController.accountData.isPosAccount;
  }

  get titleListCourierPartner() {
    if (this.isPicShuttle) {
      return "Daftar PIC Shuttle";
    }
    if (this.accountTypeDetail === "pos") {
      return "Daftar Kurir";
    }
    return "Daftar Partner";
  }

  // search
  get searchValue() {
    return DashboardController.searchListCourierPartnerCodDashboard;
  }
  set searchValue(newValue: string) {
    DashboardController.setSearchListCourierPartnerCodDashboard(newValue);
  }
  get searchPlaceholder() {
    if (this.isPicShuttle) {
      return "Cari nama pic Shuttle";
    }
    if (this.accountTypeDetail === "pos") {
      return "Cari nama kurir";
    }
    return "Cari nama partner";
  }
  onSearch(value: string) {
    this.searchValue = value;
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.fetchCourierPartner();
  }
  onClear() {
    this.searchValue = "";
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.fetchCourierPartner();
  }

  // date
  get filterDate() {
    return DashboardController.filterDateCodDashboard;
  }
  set filterDate(newValue: Date) {
    DashboardController.setFilterDateCodDashboard(newValue);
  }
  onSelectDatePicker() {
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.$nextTick(() => {
      this.fetchCourierPartner();
    });
  }

  // title for field Name
  get titleNameColumn() {
    const text = "Nama";
    if (this.isPicShuttle) {
      return text + " PIC Shuttle";
    }
    if (this.accountTypeDetail === "pos") {
      return text + " Kurir";
    }
    return text + " Partner";
  }
  // table
  get firstRowHeaderColumns() {
    const temp: Array<any> = [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-nowrap"
      },
      {
        name: this.titleNameColumn,
        styleHead: "w-40 text-left whitespace-nowrap"
      },
      {
        name: "Performa Pengiriman",
        colSpan: 2,
        styleHead:
          "w-350px text-center whitespace-nowrap border-horizontal-separation"
      },
      {
        name: "Dalam<br>Pengiriman",
        styleHead: "w-32 text-center whitespace-nowrap"
      },
      {
        name: "Pengiriman Berhasil",
        colSpan: 3,
        styleHead:
          "w-500px text-center whitespace-nowrap border-horizontal-separation"
      },
      {
        name: "Pengiriman Gagal",
        colSpan: 4,
        styleHead:
          "w-600px text-center whitespace-nowrap border-horizontal-separation"
      }
    ];
    if (this.accountTypeDetail === "pos") {
      temp.push({
        name: "Action",
        key: "actionable_column",
        styleHead: "w-24 text-left",
        showButton: () => true
      });
    }
    if (this.isPicShuttle) {
      temp.splice(2, 2);
    }
    return temp;
  }

  secondRowHeaderColumnsData2: any = [
    {
      name: "Dalam Pengiriman",
      hide: true,
      styleHead: "w-32 text-center whitespace-nowrap"
    },
    {
      name: "Belum Setor",
      styleHead: "w-40 text-left whitespace-nowrap border-vertical-separation",
      styleRender: "border-vertical-separation",
      tooltipHeader: () => {
        return this.toolTipHeaderBelumSetor;
      }
    },
    {
      name: "Sudah Setor",
      colSpan: 2,
      styleHead: "w-32 text-left whitespace-nowrap",
      tooltipHeader: () => {
        return this.toolTipHeaderSudahSetor;
      }
    },
    {
      name: "CODREJ",
      colSpan: 2,
      styleHead:
        "w-32 text-center whitespace-nowrap border-vertical-separation",
      styleRender: "border-vertical-separation",
      tooltipHeader: () => {
        return (
          "<div class='text-black-lp-300 text-12px text-left'>" +
          "Total STT COD yang gagal dikirim (CODREJ) oleh kurir." +
          "</div>"
        );
      }
    },
    {
      name: "DEX",
      colSpan: 2,
      styleHead: "w-24 text-center whitespace-nowrap",
      tooltipHeader: () => {
        return (
          "<div class='text-black-lp-300 text-12px text-left'>" +
          "Total STT COD yang gagal dikirim (DEX) oleh kurir." +
          "</div>"
        );
      }
    }
  ];
  secondRowHeaderColumnsData: any = [
    {
      name: "No.",
      hide: true,
      styleHead: "w-12 text-left whitespace-nowrap"
    },
    {
      name: "Nama Kurir",
      hide: true,
      key: "actionable_column",
      styleHead: "w-40 text-left whitespace-nowrap"
    },
    {
      name: "Sehari",
      styleHead: "w-40 text-left whitespace-nowrap border-vertical-separation",
      styleRender: "border-vertical-separation",
      tooltipHeader: () => {
        return (
          "<div class='text-black-lp-300 text-12px text-left'>" +
          "<ol style='list-style-type: decimal;'>" +
          "<li>Total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari yang sama.</li>" +
          "<li>Persentasi total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari yang sama.</li>" +
          "</ol>" +
          "</div>"
        );
      }
    },
    {
      name: "Lewat Sehari",
      styleHead: "w-40 text-left whitespace-nowrap",
      tooltipHeader: () => {
        return (
          "<div class='text-black-lp-300 text-12px text-left'>" +
          "<ol style='list-style-type: decimal;'>" +
          "<li>Total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari sebelumnya.</li>" +
          "<li>Persentasi total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari sebelumnya.</li>" +
          "</ol>" +
          "</div>"
        );
      }
    },
    ...this.secondRowHeaderColumnsData2
  ];
  get secondRowHeaderColumns() {
    if (this.accountTypeDetail === "pos") {
      this.secondRowHeaderColumnsData.push({
        name: "",
        key: "actionable_column",
        styleHead: "w-24 text-left",
        showButton: () => true
      });
    }
    if (this.isPicShuttle) {
      this.secondRowHeaderColumnsData.splice(2, 3);
    }
    return this.secondRowHeaderColumnsData;
  }

  get toolTipHeaderBelumSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) tetapi belum disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get toolTipHeaderSudahSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) dan telah disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  columnsData = [
    {
      name: "Sehari",
      hide: true,
      styleHead: "w-40 text-center whitespace-nowrap",
      render: (item: CourierPartnerCodDashboard) => {
        return (
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttPodSameDay}<span class='font-normal'>/${item.totalStt}</span></div>` +
          `<div class='text-12px text-center text-gray-lp-800'>(${(
            (item.totalSttPodSameDay / item.totalStt) *
            100
          ).toFixed(2)}%)</div>`
        );
      }
    },
    {
      name: "Lewat Sehari",
      hide: true,
      styleHead: "w-40 text-center whitespace-nowrap",
      render: (item: CourierPartnerCodDashboard) => {
        return (
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttPodDifferentDay}<span class='font-normal'>/${item.totalStt}</span></div>` +
          `<div class='text-12px text-center text-gray-lp-800'>(${(
            (item.totalSttPodDifferentDay / item.totalStt) *
            100
          ).toFixed(2)}%)</div>`
        );
      }
    },
    {
      name: "Dalam Pengiriman",
      hide: true,
      styleHead: "w-32 text-left whitespace-nowrap",
      render: (item: CourierPartnerCodDashboard) => {
        return `${item.totalSttDel} STT`;
      }
    },
    {
      name: "(POD) Belum Setor",
      hide: true,
      styleHead: "w-40 text-left whitespace-nowrap",
      render: (item: CourierPartnerCodDashboard) => {
        return `${formatPrice(item.totalOutstanding)}`;
      },
      tooltipHeader: () => {
        return this.toolTipHeaderBelumSetor;
      }
    },
    {
      name: "QRIS",
      styleHead: "w-32 text-left whitespace-nowrap border-vertical-separation",
      render: (item: CourierPartnerCodDashboard) => {
        return `${formatPrice(item.totalQrisCollected)}`;
      }
    },
    {
      name: "Tunai",
      styleHead: "w-32 text-left whitespace-nowrap",
      render: (item: CourierPartnerCodDashboard) => {
        return `${formatPrice(item.totalCollected)}`;
      }
    },
    {
      name: "Belum Setor",
      styleHead: "w-40 text-left whitespace-nowrap border-vertical-separation",
      render: (item: CourierPartnerCodDashboard) => {
        return `${item.totalSttCodrejOutstanding} STT`;
      }
    },
    {
      name: "Sudah Setor",
      styleHead: "w-40 text-left whitespace-nowrap",
      render: (item: CourierPartnerCodDashboard) => {
        return `${item.totalSttCodrejCollected} STT`;
      }
    },
    {
      name: "Belum Setor",
      styleHead: "w-40 text-left whitespace-nowrap border-vertical-separation",
      render: (item: CourierPartnerCodDashboard) => {
        return `${item.totalSttDexOutstanding} STT`;
      }
    },
    {
      name: "Sudah Setor",
      styleHead: "w-40 text-left whitespace-nowrap",
      render: (item: CourierPartnerCodDashboard) => {
        return `${item.totalSttDexCollected} STT`;
      }
    }
  ];
  get columns() {
    const temp: Array<any> = [
      {
        name: "No.",
        hide: true,
        styleHead: "w-12 text-left whitespace-nowrap",
        render: (_: any, index: number) => {
          return `<div class='text-left'>${index + 1}</div>`;
        }
      },
      {
        name: "Nama Kurir",
        hide: true,
        key: "actionable_column",
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: CourierPartnerCodDashboard) => {
          return (
            `<div class='text-black-lp-300'>${
              this.accountTypeDetail === "pos"
                ? item.courierName
                : item.partnerName
            }</div>` +
            `<div class='text-black-lp-300'>${
              this.accountTypeDetail === "pos"
                ? item.courierPhone
                : item.partnerContactPhone
            }</div>`
          );
        },
        showButton: (item: CourierPartnerCodDashboard) => {
          if (this.accountTypeDetail === "pos") {
            return item.courierPhone;
          }
          return item.partnerContactPhone;
        },
        styleCustom: "items-end",
        icon: "whatsapp",
        disableButtonValue: () => false,
        onClickValue: this.onCLickWhatsapp
      },
      ...this.columnsData
    ];
    if (this.accountTypeDetail === "pos") {
      temp.push({
        name: "",
        key: "actionable_column",
        styleHead: "w-24 text-left",
        showButton: () => true
      });
    }
    if (this.isPicShuttle) {
      temp.splice(2, 3);
    }
    return temp;
  }
  buttonActions() {
    const temp = [
      {
        icon: "pencil-alt-disable",
        label: `Detail ${this.actorName}`,
        clickFunction: (item: any) => {
          const path = this.isPicShuttle
            ? "/cod-delivery/cod-reconcile/cod-pic-shuttle/detail-pic-shuttle?"
            : "/cod-delivery/cod-reconcile/cod-courier/detail-courier?";
          this.$router.push(
            `${path}${
              new QueryParamsEntities2({
                type: this.isPicShuttle ? "pic" : "courier",
                "driver-phone": item.courierPhone,
                date: formatDateNumber(this.filterDate)
              }).queryString
            }`
          );
        }
      },
      {
        icon: "download-gray",
        label: `Download Detail ${this.actorName}`,
        clickFunction: (item: any) => {
          DashboardController.getCourierDetailDownload({
            driverPhone: item.courierPhone,
            isPic: this.isPicShuttle
          });
        }
      },
      {
        icon: "setor-harian-kurir",
        label: `Setor Harian ${this.actorName}`,
        clickFunction: (item: any) => {
          this.popUpSetorHarian(true, item);
        }
      }
    ];
    const isAbleToDeposit = this.isPicShuttle
      ? FlagsPermissionCodReconcile.permission_cod_reconcile_pic_deposit.isEnabled()
      : FlagsPermissionCodReconcile.permission_cod_reconcile_deposit.isEnabled();

    if (!isAbleToDeposit) {
      temp.pop();
    }

    return temp;
  }

  // data table
  get dataCourierPartner() {
    return DashboardController.listCourierPartnerCodDashboard.data;
  }
  get paginationCourierPartner() {
    return DashboardController.listCourierPartnerCodDashboard.pagination;
  }
  get isLoadingCourierPartner() {
    return DashboardController.loadingListCourierPartnerCodDashboard;
  }
  get isErrorCourierPartner() {
    return DashboardController.errorListCourierPartnerCodDashboard;
  }
  fetchCourierPartner() {
    if (this.isPicShuttle) {
      this.fetchListPICShuttle();
    } else {
      DashboardController.getListCourierPartnerCodDashboard();
    }
  }

  async fetchListPICShuttle() {
    const accountLocationList =
      AccountController.accountData.account_location_list;
    const params = new ListPICShuttledRequest({
      page: DashboardController.listCourierPartnerCodDashboard.pagination.page,
      filterDate: moment(this.filterDate).format("YYYY-MM-DD"),
      search: this.searchValue,
      cityCode: accountLocationList?.length
        ? accountLocationList.map((list: any) => list.city_code).join(",")
        : ""
    });
    DashboardController.setErrorListCourierPartnerCodDashboard("");
    DashboardController.setLoadingListCourierPartnerCodDashboard(true);
    await ReconcileCodController.getListPICShuttle(params)
      .then((resp: ListCourierPartnerCodDashboard) => {
        if (!resp.data.length && resp.pagination.page === 1) {
          DashboardController.setListCourierPartnerCodDashboard(
            new ListCourierPartnerCodDashboard()
          );
          DashboardController.setErrorListCourierPartnerCodDashboard(
            "notfound"
          );
        } else {
          DashboardController.addDatalistCourierPartnerCodDashboard(resp);
        }
      })
      .catch((err: any) => {
        DashboardController.setListCourierPartnerCodDashboard(
          new ListCourierPartnerCodDashboard()
        );
        DashboardController.setErrorListCourierPartnerCodDashboard(
          err.response ? "server" : "internet"
        );
      })
      .finally(() => {
        DashboardController.setLoadingListCourierPartnerCodDashboard(false);
      });
  }

  // whatsapp courier / partner
  onCLickWhatsapp(item: CourierPartnerCodDashboard) {
    const phoneNumber =
      this.accountTypeDetail === "pos"
        ? item.courierPhone
        : item.partnerContactPhone;
    const messageTo =
      this.accountTypeDetail === "pos"
        ? `Kurir ${item.courierName}`
        : `Agen ${item.partnerName}`;
    const templateMessage =
      `Halo ${messageTo}\n` +
      `Kami menginformasikan ada STT COD perlu diselesaikan proses secepatnya:\n` +
      `${item.totalSttDel} STT COD masih proses pengiriman\n` +
      `${item.totalSttDexCollected +
        item.totalSttCodrejCollected} STT COD harus ${
        this.accountTypeDetail === "pos"
          ? "dikembalikan ke"
          : "dikumpulkan oleh"
      } POS\n` +
      `${formatPrice(item.totalOutstanding)} Dana harus ${
        this.accountTypeDetail === "pos" ? "disetor ke" : "dikumpulkan oleh"
      } POS\n` +
      `Harap langsung memproses pengiriman STT dan lakukan ${
        this.accountTypeDetail === "pos" ? "penyetoran" : "pengumpulan"
      } Terima kasih`;
    whatsapp(phoneNumber, templateMessage);
  }

  popupSetorHarianModelValue = false;
  detailDataCourier = {
    courierName: "",
    phoneNumber: "",
    depositMoney: 0,
    sttCodrej: 0,
    sttDex: 0
  };
  popUpSetorHarian(bool: boolean, data: any) {
    this.popupSetorHarianModelValue = bool;
    this.detailDataCourier.courierName = data.courierName;
    this.detailDataCourier.phoneNumber = data.courierPhone;
    this.detailDataCourier.depositMoney = data.totalOutstanding;
    this.detailDataCourier.sttCodrej = data.totalSttCodrejOutstanding;
    this.detailDataCourier.sttDex = data.totalSttDexOutstanding;
  }

  get cityCodes() {
    const cities = this.dataProfile.account_location_list?.map(
      item => item.city_code
    );
    return cities?.join(",");
  }

  downloadSummary() {
    let downloadType = this.accountTypeDetail === "pos" ? "pos" : "consol";
    if (this.isPicShuttle) downloadType = "pic";
    const filter = new DownloadSummaryCodRequest({
      filterDate: this.$moment(this.filterDate).format("YYYY-MM-DD"),
      cityCode: this.accountTypeDetail === "pos" ? "" : this.cityCodes
    });

    DashboardController.getSummaryDownload({
      type: downloadType,
      filter: filter
    });
  }
}
