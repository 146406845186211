import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-3" }
const _hoisted_2 = { class: "grid gap-3 grid-cols-1 md:grid-cols-3 lg:grid-cols-4" }
const _hoisted_3 = { class: "border border-gray-lp-1300 rounded-lg col-span-1 md:col-span-2 lg:col-span-3" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3" }
const _hoisted_5 = { class: "grid gap-3 grid-cols-1 md:grid-cols-3 lg:grid-cols-4" }
const _hoisted_6 = { class: "border border-gray-lp-1300 rounded-lg col-span-1 md:col-span-2 lg:col-span-3" }
const _hoisted_7 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3" }
const _hoisted_8 = { class: "row-span-2 border-r border-gray-lp-1300" }
const _hoisted_9 = { class: "border-b border-r border-gray-lp-1300" }
const _hoisted_10 = { class: "border-b border-gray-lp-1300" }
const _hoisted_11 = { class: "border-r border-gray-lp-1300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DetailStatusSection = _resolveComponent("DetailStatusSection")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, { label: "Overall Summary" }),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DetailStatusSection, _mergeProps({
            class: "border-b md:border-r lg:border-b-0 border-gray-lp-1300 py-4 px-3",
            "swap-display": ""
          }, _ctx.podDexData('total')), null, 16),
          _createVNode(_component_DetailStatusSection, _mergeProps({
            class: "border-b md:border-r lg:border-b-0 border-gray-lp-1300 py-4 px-3",
            "swap-display": ""
          }, _ctx.podDexData('outstanding')), null, 16),
          _createVNode(_component_DetailStatusSection, _mergeProps({
            class: "border-b md:border-r-none lg:border-b-0 border-gray-lp-1300 py-4 px-3",
            "swap-display": ""
          }, _ctx.podDexData('collected')), null, 16)
        ])
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode("div", _hoisted_7, [
          _createVNode("div", _hoisted_8, [
            _createVNode(_component_DetailStatusSection, _mergeProps({
              class: "py-4 px-3",
              "swap-display": ""
            }, _ctx.statusTotalFailedSection), null, 16)
          ]),
          _createVNode("div", _hoisted_9, [
            _createVNode(_component_DetailStatusSection, _mergeProps({
              class: "py-4 px-3",
              "swap-display": ""
            }, _ctx.codRejDexData('failed-codrej')), null, 16)
          ]),
          _createVNode("div", _hoisted_10, [
            _createVNode(_component_DetailStatusSection, _mergeProps({
              class: "py-4 px-3",
              "swap-display": ""
            }, _ctx.codRejDexData('collected-codrej')), null, 16)
          ]),
          _createVNode("div", _hoisted_11, [
            _createVNode(_component_DetailStatusSection, _mergeProps({
              class: "py-4 px-3",
              "swap-display": ""
            }, _ctx.codRejDexData('failed-dex')), null, 16)
          ]),
          _createVNode("div", null, [
            _createVNode(_component_DetailStatusSection, _mergeProps({
              class: "py-4 px-3",
              "swap-display": ""
            }, _ctx.codRejDexData('collected-dex')), null, 16)
          ])
        ])
      ])
    ])
  ]))
}