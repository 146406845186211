
import { Vue, Options, prop } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

class Props {
  statusName = prop<string>({
    default: "",
    type: String
  });
  statusSubName = prop<string>({
    default: "",
    type: String
  });
  toolTipDescription = prop<string>({
    default: "",
    type: String
  });
  sttDisplay = prop<string>({
    default: "",
    type: String
  });
  percentageSttDisplay = prop<string>({
    default: "",
    type: String
  });
  totalAmountSttDisplay = prop<string>({
    default: "",
    type: String
  });
  backgroundColor = prop<string>({
    default: "",
    type: String
  });
  iconName = prop<string>({
    default: "",
    type: String
  });
  iconColor = prop<string>({
    default: "",
    type: String
  });
  swapDisplay = prop<boolean>({
    default: false,
    type: Boolean
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean
  });
  detail = prop<any[]>({
    default: [],
    type: Array
  });
}
@Options({
  components: {
    Skeleton
  }
})
export default class DetailStatusSection extends Vue.with(Props) {}
