import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-2 overflow-auto" }
const _hoisted_2 = { class: "flex flex-row gap-x-4" }
const _hoisted_3 = {
  class: "overflow-y-auto relative",
  style: {"height":"calc(100vh - 170px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Search = _resolveComponent("Search")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_PopupSetorHarian = _resolveComponent("PopupSetorHarian")!
  const _component_WidgetDownload = _resolveComponent("WidgetDownload")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Title, {
        class: "mb-6",
        label: _ctx.titleListCourierPartner
      }, null, 8, ["label"]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_Search, {
          onInput: _ctx.onSearch,
          onClear: _ctx.onClear,
          value: _ctx.searchValue,
          placeholder: _ctx.searchPlaceholder,
          class: "w-72"
        }, null, 8, ["onInput", "onClear", "value", "placeholder"]),
        _createVNode(_component_DatePicker, {
          onSelected: _ctx.onSelectDatePicker,
          modelValue: _ctx.filterDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterDate = $event)),
          "max-date": new Date(),
          class: "w-1/5"
        }, null, 8, ["onSelected", "modelValue", "max-date"]),
        _createVNode(_component_LpButton, {
          textColor: "white",
          title: "Download Detail",
          iconLeft: "download-new",
          iconV2: "",
          small: "",
          customClass: "w-full md:w-50",
          onClick: _ctx.downloadSummary
        }, null, 8, ["onClick"])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_TableV2, {
          columns: _ctx.columns,
          firstRowHeader: _ctx.firstRowHeaderColumns,
          secondRowHeader: _ctx.secondRowHeaderColumns,
          data: _ctx.dataCourierPartner,
          loading: _ctx.isLoadingCourierPartner,
          pagination: _ctx.paginationCourierPartner,
          "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.paginationCourierPartner = $event)),
          onRequest: _ctx.fetchCourierPartner,
          groupOptions: _ctx.buttonActions,
          groupAction: "",
          isNoPaddingBottom: "",
          pinnedSubstractHeight: "170px",
          isShowPagination: false,
          borderTop: false,
          borderBottom: false,
          infiniteScroll: "",
          iconColor: "green-lp-100",
          isRightFreeze: true,
          totalColumnFreeze: _ctx.isPos ? 1 : 0
        }, null, 8, ["columns", "firstRowHeader", "secondRowHeader", "data", "loading", "pagination", "onRequest", "groupOptions", "totalColumnFreeze"]),
        (_ctx.isErrorCourierPartner)
          ? (_openBlock(), _createBlock(_component_ErrorMessage, {
              key: 0,
              errorType: _ctx.isErrorCourierPartner,
              customClass: "h-auto mt-16"
            }, null, 8, ["errorType"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.popupSetorHarianModelValue)
        ? (_openBlock(), _createBlock(_component_PopupSetorHarian, {
            key: 0,
            isPicShuttle: _ctx.isPicShuttle,
            modelValue: _ctx.popupSetorHarianModelValue,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.popupSetorHarianModelValue = $event)),
            detailDataCourier: _ctx.detailDataCourier,
            type: _ctx.listType
          }, null, 8, ["isPicShuttle", "modelValue", "detailDataCourier", "type"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_WidgetDownload)
  ], 64))
}