
import { Options, Vue } from "vue-class-component";
import Accordion from "@/app/ui/components/accordion/index.vue";
import {
  formatDateNormal,
  formatPrice,
  formatPriceRP,
  whatsapp
} from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import {
  CourierPartnerCodDashboard,
  DetailCourierPerStatusCodDashboardData
} from "@/domain/entities/Dashboard";
import PopupSetorHarian from "../components/popup-setor-harian-kurir-v2.vue";
import { FlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";
import { CodReconcileController } from "@/app/ui/controllers/CodReconcileController";
import {
  CourierDetailApiRequest,
  CourierDetailPendingReconcile,
  CourierSummary,
  SttInDelivery
} from "@/domain/entities/CodReconcile";
import PopupHarusDisetor from "@/app/ui/views/cod-delivery-v2/components/popup-harus-disetor.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
  components: {
    Accordion,
    PopupSetorHarian,
    PopupHarusDisetor
  }
})
export default class Detail extends Vue {
  successDeliveryExpand = false;
  failedDeliveryExpand = false;
  onDeliveryExpand = false;
  dailyDepositFromAmountDue = false;
  openPopupAmountDue = false;
  onDeliveryFilterDate: any = null;
  summaryDetail = new CourierSummary();
  deliverySummaryDetail = new CourierSummary();
  formatPrice = formatPrice;
  filterDate = new Date();
  pendingReconcileData: CourierDetailPendingReconcile[] = [];
  dailyDepositDateFilterDate: any = null;
  isFromPopupAmountDue = false;

  // loader
  successDeliveryLoading = false;
  failedDeliveryLoading = false;
  onDeliveryLoading = false;
  detailCourierLoading = false;

  errorCause = "";
  isLoading = false;

  mounted() {
    this.filterDate = this.date;
    this.dailyDepositDateFilterDate = this.date;
    DashboardController.setPhoneNumberCourier(this.driverPhone);
    DashboardController.setFilterDateCodDashboard(this.date);
    DashboardController.setSttDataCourierData([]);
    this.fetchDetailCourier(); 
  }
  get maxDate() {
    return new Date();
  }
  get driverPhone(): string {
    return decodeURIComponent(
      this.$route.query["driver-phone"] as string
    ).replaceAll("+", "");
  }
  get dashboardDeliveryCourierDetail() {
    return CodReconcileController.dashboardDeliveryCourierDetail;
  }

  get partnerName() {
    const splittedString = this.dashboardDeliveryCourierDetail.partnerName.split(
      " "
    );
    return splittedString.slice(0, splittedString.length - 1).join(" ");
  }

  get courier3lc() {
    const splittedString = this.dashboardDeliveryCourierDetail.partnerName.split(
      " "
    );

    const splitted3lc = splittedString[splittedString.length - 1].split("-");

    return splitted3lc[0].replace("[", "");
  }

  fetchDetailCourier() {
    this.detailCourierLoading = true;
    this.errorCause = "";
    CodReconcileController.getDashboardDeliveryCourierDetail(this.driverPhone)
      .then(() => {
        this.fetchSummaryCodDashboard();
        this.getDetailSummaryByCourier();
        this.getDetailSummaryByCourier(true);
        this.getPendingReconcile();
      })
      .catch(err => {
        this.errorCause = err.response ? "server" : "internet";
      })
      .finally(() => {
        this.detailCourierLoading = false;
      });
  }

  goBack() {
    this.$router.push(this.$route.meta.before as string);
  }
  downloadDeposit() {
    this.dailyDepositDateFilterDate = this.filterDate;
    const { fullname } = this.dashboardDeliveryCourierDetail;
    this.isFromPopupAmountDue = false;

    // to do download deposit
    this.popUpSetorHarian(
      true,
      new CourierPartnerCodDashboard({
        courierName: fullname,
        courierPhone: this.driverPhone
      })
    );
  }
  downloadDetail() {
    // to do download detail
    DashboardController.getCourierDetailDownload({
      driverPhone: this.driverPhone,
      isPic: this.type !== "courier"
    });
  }

  get detail() {
    const detail = {
      title: this.type === "courier" ? "Detail Kurir" : "Detail PIC Shuttle",
      name: this.type === "courier" ? "Nama Kurir" : "Nama PIC Shuttle",
      setor: this.type === "courier" ? "Setor harian" : "Setor harian"
    };

    return detail;
  }

  get isConsole() {
    return AccountController.accountData.isConsoleAccount;
  }

  get isSubConsole() {
    return AccountController.accountData.isSubConsoleAccount;
  }

  get type() {
    return this.$route.query["type"];
  }
  get date(): Date {
    return new Date(this.$route.query["date"] as string);
  }
  get showButtonDeposit() {
    return this.type === "courier"
      ? FlagsPermissionCodReconcile.permission_cod_reconcile_deposit.isEnabled() ||
          this.isConsole ||
          this.isSubConsole
      : FlagsPermissionCodReconcile.permission_cod_reconcile_pic_deposit.isEnabled();
  }

  get successfulDeliverySummary() {
    return (
      this.summaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "POD"
      ) || new SttInDelivery()
    );
  }

  get failedDeliverySummaryTotalStt() {
    return (
      (this.summaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "CODREJ"
      )?.count || 0) +
      (this.summaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "DEX"
      )?.count || 0)
    );
  }

  get failedDeliverySummaryTotalPieces() {
    return (
      (this.summaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "CODREJ"
      )?.pieces || 0) +
      (this.summaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "DEX"
      )?.pieces || 0)
    );
  }

  get onDeliverySummary() {
    return (
      this.deliverySummaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "DEL"
      ) || new SttInDelivery()
    );
  }

  get codAmountDepositPending() {
    return this.formatPrice(this.summaryDetail.codAmountDeposit.pending);
  }

  get sttDepositTotalPieces() {
    return this.summaryDetail.sttDeposit.reduce(
      (a, b) => a + b.pendingPieces,
      0
    );
  }

  get sttDepositTotal() {
    return this.summaryDetail.sttDeposit.reduce((a, b) => a + b.pending, 0);
  }

  get totalCodAmount() {
    return this.formatPrice(
      this.summaryDetail.codAmountDeposit.pending +
        this.summaryDetail.codAmountDeposit.collected
    );
  }

  get pendingCodAmount() {
    return this.formatPrice(this.summaryDetail.codAmountDeposit.pending);
  }

  get collectedCodAmount() {
    return this.formatPrice(this.summaryDetail.codAmountDeposit.collected);
  }

  // DEX
  get totalDex() {
    const totalDex = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "DEX"
    );
    return (totalDex?.pending || 0) + (totalDex?.collected || 0);
  }

  get totalPiecexDex() {
    const totalDex = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "DEX"
    );
    return (totalDex?.collectedPieces || 0) + (totalDex?.pendingPieces || 0);
  }

  get pendingDex() {
    const totalDex = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "DEX"
    );

    return totalDex?.pending || 0;
  }

  get pendingPiecexDex() {
    const totalDex = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "DEX"
    );

    return totalDex?.pendingPieces || 0;
  }

  get collectedDex() {
    const totalDex = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "DEX"
    );

    return totalDex?.collected || 0;
  }

  get collectedPiecexDex() {
    const totalDex = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "DEX"
    );

    return totalDex?.collectedPieces || 0;
  }

  // CODREJ
  get totalCodrej() {
    const totalCodrej = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "CODREJ"
    );
    return (totalCodrej?.pending || 0) + (totalCodrej?.collected || 0);
  }

  get totalPiecesCodrej() {
    const totalCodrej = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "CODREJ"
    );
    return (
      (totalCodrej?.collectedPieces || 0) + (totalCodrej?.pendingPieces || 0)
    );
  }

  get pendingCodRej() {
    const totalCodrej = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "CODREJ"
    );

    return totalCodrej?.pending || 0;
  }

  get pendingPiecesCodrej() {
    const totalCodrej = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "CODREJ"
    );

    return totalCodrej?.pendingPieces || 0;
  }

  get collectedCodrej() {
    const totalCodrej = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "CODREJ"
    );

    return totalCodrej?.collected || 0;
  }

  get collectedPieceCodrej() {
    const totalCodrej = this.summaryDetail.sttDeposit.find(
      item => item.status.toUpperCase() === "CODREJ"
    );

    return totalCodrej?.collectedPieces || 0;
  }

  // in delivery
  get totalInDelivery() {
    return this.summaryDetail.sttInDelivery.reduce((a, b) => a + b.count, 0);
  }

  get totalPiecesInDelivery() {
    return this.summaryDetail.sttInDelivery.reduce((a, b) => a + b.pieces, 0);
  }

  get totalDelInDelivery() {
    return (
      this.summaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "DEL"
      )?.count || 0
    );
  }

  get totalPiecesDelInDelivery() {
    return (
      this.summaryDetail.sttInDelivery.find(
        item => item.status.toUpperCase() === "DEL"
      )?.pieces || 0
    );
  }

  get totalPodCodrejDex() {
    return this.summaryDetail.sttInDelivery
      .filter(item => item.status.toUpperCase() !== "DEL")
      .reduce((a, b) => a + b.count, 0);
  }

  get totalPiecesPodCodrejDex() {
    return this.summaryDetail.sttInDelivery
      .filter(item => item.status.toUpperCase() !== "DEL")
      .reduce((a, b) => a + b.pieces, 0);
  }

  listOfDetailPerStatus: DetailCourierPerStatusCodDashboardData[] = [
    new DetailCourierPerStatusCodDashboardData({
      status: "success",
      loading: true
    }),
    new DetailCourierPerStatusCodDashboardData({
      status: "failed",
      loading: true
    }),
    new DetailCourierPerStatusCodDashboardData({
      status: "onprogress",
      loading: true
    })
  ];
  async fetchSummaryCodDashboard(firstLoad = true) {
    // fetch success status
    this.successDeliveryLoading = true;
    CodReconcileController.getCourierDetailPerStatus(
      new CourierDetailApiRequest({
        driverPhone: this.driverPhone,
        filterDate: formatDateNormal(this.filterDate, "YYYY-MM-DD"),
        picPhone: this.driverPhone,
        status: "success",
        type: this.type as string,
        isAllType: true
      })
    ).finally(() => {
      this.successDeliveryLoading = false;
    });

    // fetch failed status
    this.failedDeliveryLoading = true;
    CodReconcileController.getCourierDetailPerStatus(
      new CourierDetailApiRequest({
        driverPhone: this.driverPhone,
        filterDate: formatDateNormal(this.filterDate, "YYYY-MM-DD"),
        picPhone: this.driverPhone,
        status: "failed",
        type: this.type as string,
        isAllType: true
      })
    ).finally(() => {
      this.failedDeliveryLoading = false;
    });

    if (firstLoad) {
      // fetch onprogress status
      this.onDeliveryLoading = true;
      CodReconcileController.getCourierDetailPerStatus(
        new CourierDetailApiRequest({
          driverPhone: this.driverPhone,
          filterDate: "",
          picPhone: this.driverPhone,
          status: "onprogress",
          type: this.type as string,
          isAllType: true
        })
      ).finally(() => {
        this.onDeliveryLoading = false;
      });
    }
  }

  get successfulDelivery() {
    return CodReconcileController.courierDetailListSuccess;
  }

  get failedDelivery() {
    return CodReconcileController.courierDetailListFailed;
  }

  get onDelivery() {
    return CodReconcileController.courierDetailListOnProgress;
  }

  getCodAmount(item: any) {
    return formatPriceRP(item.sttCodAmount);
  }

  columnData(params: {
    name: string;
    key: string;
    styleHead?: string;
    styleBody?: string;
  }) {
    const { name, key, styleHead, styleBody } = params;
    return {
      name,
      styleHead: `${styleHead} text-left whitespace-no-wrap`,
      styleBody: `${styleBody} text-center`,
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item[key]}</div>`;
      }
    };
  }
  get rowsData() {
    return [
      this.columnData({
        name: "No. STT",
        styleHead: "w-40 text-sm",
        key: "sttNo"
      }),
      this.columnData({
        name: "Status Terakhir",
        styleHead: "w-50 text-sm",
        key: "sttStatus"
      }),
      this.columnData({
        name: "Tipe Pembayaran COD",
        styleHead: "w-50 text-sm",
        key: "sttPaymentMethod"
      }),
      this.columnData({
        name: "Status setoran",
        styleHead: "w-50 text-sm",
        key: "sttCollectionStatus"
      })
    ];
  }
  get rows() {
    return [
      {
        name: "No.",
        styleHead: "w-20 text-left whitespace-no-wrap text-sm",
        styleBody: "text-center",
        render: (item: any, index: any) => {
          return `<div class='text-left text-gray-lp-300'>${index + 1}</div>`;
        }
      },
      ...this.rowsData,
      {
        name: "Nilai COD",
        styleHead: "w-50 text-left whitespace-no-wrap text-sm",
        styleBody: "text-center",
        render: (item: any) => {
          return this.renderCodAmount(item);
        }
      }
    ];
  }
  get columnSuccessful() {
    const courierName: any = {
      name: "Nama Kurir",
      styleHead: "w-50 text-left whitespace-nowrap",
      render: (item: any) => {
        return (
          `<div class='text-black-lp-300'>${item.sttDriverName}</div>` +
          `<div class='text-black-lp-300'>${item.sttDriverPhone}</div>`
        );
      },
      showButton: (item: any) => {
        return item.sttDriverPhone;
      },
      styleCustom: "items-end"
    };

    if (this.type === "pic") {
      return this.rows.splice(2, 0, courierName);
    }

    return this.rows;
  }

  get rowsFailed() {
    return [
      {
        name: "No.",
        styleHead: "w-20 text-left whitespace-no-wrap text-sm",
        styleBody: "text-center",
        render: (item: any, index: any) => {
          return `<div class='text-left text-gray-lp-300'>${index + 1}</div>`;
        }
      },
      this.columnData({
        name: "No. STT",
        styleHead: "w-40 text-sm",
        key: "sttNo"
      }),
      {
        name: "Status terakhir",
        styleHead: "w-50 text-left whitespace-no-wrap text-sm",
        styleBody: "text-center",
        render: (item: any) => {
          return `<div class='text-left text-gray-lp-300'>${item.sttStatus}</div>`;
        }
      },
      this.columnData({
        name: "Status setoran",
        styleHead: "w-50 text-sm",
        key: "sttCollectionStatus"
      }),
      this.columnData({
        name: "Alasan",
        styleHead: "w-50 text-sm",
        key: "sttReason"
      }),
      {
        name: "Nama Penerima",
        styleHead: "w-50 text-left whitespace-no-wrap text-sm",
        styleBody: "text-center",
        render: (item: any) => {
          return `
            <div>
              <div class='text-left text-gray-lp-300'>${
                item.sttRecipientName
              }</div>
              <div class="flex flex-row items-center font-semibold gap-2">
                <div class='text-left text-gray-lp-300'>${
                  item.sttRecipientPhone
                }</div>
                <img src="${require("@/app/ui/assets/svg/ics_f_whatsapp.svg")}" />
              </div>
            </div>
          `;
        },
        onClickValue: (item: any) => {
          window.open(
            `https://api.whatsapp.com/send?phone=${item.sttRecipientPhone}`,
            "_blank"
          );
        }
      }
    ];
  }
  get columnFailed() {
    const courierName: any = {
      name: "Nama Kurir",
      styleHead: "w-50 text-left whitespace-nowrap text-sm",
      render: (item: any) => {
        return (
          `<div class='text-black-lp-300'>${item.sttDriverName}</div>` +
          `<div class='text-black-lp-300'>${item.sttDriverPhone}</div>`
        );
      },
      showButton: (item: any) => {
        return item.sttDriverPhone;
      },
      styleCustom: "items-end"
    };

    if (this.type === "pic") {
      this.rowsFailed.splice(2, 0, courierName);
    }

    return this.rowsFailed;
  }

  onCLickWhatsapp() {
    return true;
  }

  columnOnDelivery = [
    {
      name: "No.",
      styleHead: "w-20 text-left whitespace-no-wrap text-sm",
      styleBody: "text-center",
      render: (item: any, index: any) => {
        return `<div class='text-left text-gray-lp-300'>${index + 1}</div>`;
      }
    },
    {
      name: "No. STT",
      styleHead: "w-40 text-left whitespace-no-wrap text-sm",
      styleBody: "text-center",
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item.sttNo}</div>`;
      }
    },
    {
      name: "Tanggal DEL",
      styleHead: "w-50 text-left whitespace-no-wrap text-sm",
      styleBody: "text-center",
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${formatDateNormal(
          item.sttDeliveryDate,
          "DD MMMM YYYY"
        )}</div>`;
      }
    },
    {
      name: "Status terakhir",
      styleHead: "w-50 text-left whitespace-no-wrap text-sm",
      styleBody: "text-center",
      render: (item: any) => {
        return `<div class='text-left text-gray-lp-300'>${item.sttStatus}</div>`;
      }
    },
    {
      name: "Nilai COD",
      styleHead: "w-50 text-left whitespace-no-wrap text-sm",
      styleBody: "text-center",
      render: (item: any) => {
        return this.renderCodAmount(item);
      }
    },
    {
      name: "Nama Penerima",
      styleHead: "w-50 text-left whitespace-no-wrap text-sm",
      styleBody: "text-center",
      render: (item: any) => {
        return `
          <div>
            <div class='text-left text-gray-lp-300'>${
              item.sttRecipientName
            }</div>
            <div class="flex flex-row items-center font-semibold gap-2">
              <div class='text-left text-gray-lp-300'>${
                item.sttRecipientPhone
              }</div>
              <img src="${require("@/app/ui/assets/svg/ics_f_whatsapp.svg")}" />
            </div>
          </div>
        `;
      },
      onClickValue: (item: any) => {
        window.open(
          `https://api.whatsapp.com/send?phone=${item.sttRecipientPhone}`,
          "_blank"
        );
      }
    }
  ];

  renderCodAmount(item: any) {
    return `<div class='text-left text-gray-lp-300'>${this.getCodAmount(
      item
    )}</div>`;
  }

  // table
  get firstRowHeaderColumns() {
    const rows = [
      {
        name: "Performa Pengiriman",
        colSpan: 2,
        styleHead:
          "w-500px text-center whitespace-no-wrap border-horizontal-separation"
      },
      {
        name: "Dalam<br>Pengiriman",
        styleHead: "w-48 text-center whitespace-no-wrap"
      },
      {
        name: "Pengiriman Berhasil",
        colSpan: 3,
        styleHead:
          "w-500px text-left whitespace-no-wrap border-horizontal-separation"
      },
      {
        name: "Pengiriman Gagal",
        colSpan: 4,
        styleHead:
          "w-500px text-left whitespace-no-wrap border-horizontal-separation"
      }
    ];

    return this.type === "courier" ? rows : rows.slice(2);
  }
  get tooltipHeaderBelumSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) tetapi belum disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get tooltipHeaderSudahSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) dan telah disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  // data table
  get dataCourierPartner() {
    return [
      {
        performance: 1,
        onProccess: 2
      }
    ];
  }

  onClickWhatsapp() {
    const templateMessage =
      `Halo ${this.dashboardDeliveryCourierDetail.fullname}\n` +
      `Kami menginformasikan ada STT COD perlu diselesaikan proses secepatnya:\n` +
      `${this.totalDelInDelivery +
        this.pendingDex} STT COD masih proses pengiriman\n` +
      `${this.pendingDex +
        this.pendingCodRej} STT COD harus dikembalikan ke POS\n` +
      `${this.totalCodAmount} Dana harus disetor ke POS\n` +
      `Harap langsung memproses pengiriman STT dan lakukan penyetoran
       Terima kasih`;

    whatsapp(this.driverPhone, templateMessage);
  }

  popupSetorHarianModelValue = false;
  detailDataCourier = new CourierPartnerCodDashboard();
  popUpSetorHarian(bool: boolean, data: CourierPartnerCodDashboard) {
    this.detailDataCourier = data;
    this.popupSetorHarianModelValue = bool;
  }

  get dailyDepositDateFilterDateString() {
    return formatDateNormal(this.dailyDepositDateFilterDate, "YYYY-MM-DD");
  }

  onDeliveryDateSelected(val: any) {
    this.onDeliveryFilterDate = val;
    this.onDeliveryLoading = true;
    CodReconcileController.getCourierDetailPerStatus(
      new CourierDetailApiRequest({
        driverPhone: this.driverPhone,
        filterDate: this.onDeliveryFilterDate
          ? formatDateNormal(val, "YYYY-MM-DD")
          : "",
        picPhone: this.driverPhone,
        status: "onprogress",
        type: this.type as string,
        isAllType: true
      })
    ).finally(() => {
      this.onDeliveryLoading = false;
    });
    this.getDetailSummaryByCourier(true);
  }

  getDetailSummaryByCourier(isForDelivery = false) {
    MainAppController.showLoading();
    let filterDate: any = formatDateNormal(this.filterDate, "YYYY-MM-DD");

    if (isForDelivery) {
      filterDate = this.onDeliveryFilterDate
        ? formatDateNormal(this.onDeliveryFilterDate, "YYYY-MM-DD")
        : formatDateNormal(new Date(), "YYYY-MM-DD");
    }

    CodReconcileController.getCourierSummaryDetailV2(
      new CourierDetailApiRequest({
        driverPhone: this.driverPhone,
        type: this.type === "courier" ? "DRIVER" : (this.type as string),
        filterDate: filterDate
      })
    )
      .then(res => {
        if (isForDelivery) {
          this.deliverySummaryDetail = res;
        } else {
          this.summaryDetail = res;
        }
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  getPendingReconcile() {
    CodReconcileController.getDetailCourierPendingReconcile(
      new CourierDetailApiRequest({
        filterDate: formatDateNormal(this.filterDate, "YYYY-MM-DD"),
        driverPhone: this.driverPhone
      })
    ).then(res => {
      this.pendingReconcileData = res;
    });
  }

  onFilterDate() {
    this.fetchSummaryCodDashboard(false);
    this.getDetailSummaryByCourier();
    this.getPendingReconcile();
  }

  openDailyDepositFromAmountDue(data: any) {
    this.isFromPopupAmountDue = true;
    this.dailyDepositDateFilterDate = new Date(data.dateGroup);
    this.dailyDepositFromAmountDue = true;
    this.popUpSetorHarian(true, this.detailDataCourier);
  }

  onClickOpenPopupAmountDue() {
    this.openPopupAmountDue = true;
    this.detailDataCourier.courierName = this.dashboardDeliveryCourierDetail.fullname;
    this.detailDataCourier.courierPhone = this.driverPhone
  }

  closeAmountDue(data: any) {
    this.openPopupAmountDue = data;
  }

  onCloseDepositDaily(success = false) {
    if (this.isFromPopupAmountDue && !success) {
      this.onClickOpenPopupAmountDue();
    }
    if (success) {
      this.fetchDetailCourier();
    }
  }
}
