
  import { Options, Vue } from "vue-class-component";
  import CodPicShuttleV1 from "../../cod-delivery/cod-reconcile/tabs/cod-pic-shuttle.vue";
  import CodPicShuttleV2 from "../../cod-delivery-v2/cod-reconcile/tabs/cod-pic-shuttle.vue";
  @Options({
    components: {
      CodPicShuttleV1,
      CodPicShuttleV2,
    },
  })
  export default class ConfigCodPicShuttle extends Vue {
    get configCodReconcile() {
      return "v1";
    }
  
    get renderedPage(): string {
      return this.configCodReconcile === "v2" ? "CodPicShuttleV2" : "CodPicShuttleV1";
    }
  }
  