import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/app/ui/assets/svg/arrow-left.svg'


const _hoisted_1 = { class: "h-full relative" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center border-b border-gray-lp-400" }
const _hoisted_3 = { class: "flex flex-row my-6" }
const _hoisted_4 = { class: "font-semibold text-20px mr-4 text-black-lp-200" }
const _hoisted_5 = { class: "h-full overflow-y-auto pb-40" }
const _hoisted_6 = { class: "flex flex-row justify-between pb-4" }
const _hoisted_7 = { class: "flex flex-row" }
const _hoisted_8 = { class: "flex flex-row" }
const _hoisted_9 = { class: "flex flex-row" }
const _hoisted_10 = { class: "overflow-y-auto relative mb-6" }
const _hoisted_11 = { class: "overflow-auto relative mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Icons = _resolveComponent("Icons")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_PopupSetorHarian = _resolveComponent("PopupSetorHarian")!
  const _component_WidgetDownload = _resolveComponent("WidgetDownload")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("img", {
            src: _imports_0,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args))),
            class: "w-5 mr-3 cursor-pointer",
            alt: "back"
          }),
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.detail.title), 1)
        ])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_DataWrapper, {
              label: _ctx.detail.name,
              value: _ctx.detailCourier.courierName,
              class: "mt-6"
            }, null, 8, ["label", "value"]),
            _createVNode(_component_DataWrapper, {
              label: "Nomor Handphone",
              class: "mt-6 pl-12"
            }, {
              default: _withCtx(() => [
                _createVNode("div", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.detailCourier.courierPhone) + " ", 1),
                  _createVNode(_component_Icons, {
                    name: "whatsapp",
                    class: "w-4 mb-2 ml-2 text-green-lp-100 cursor-pointer",
                    onClick: _ctx.onClickWhatsapp
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_9, [
            (_ctx.showButtonDeposit)
              ? (_openBlock(), _createBlock(_component_lp_button, {
                  key: 0,
                  class: "mt-6 w-auto mr-4",
                  title: _ctx.detail.setor,
                  outline: "",
                  "text-color": "red-lp-100",
                  color: "red-lp-100",
                  "custom-class": "px-12",
                  iconLeft: "setor-harian-kurir-red",
                  onClick: _ctx.downloadDeposit
                }, null, 8, ["title", "onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_lp_button, {
              class: "mt-6 w-auto",
              title: "Download Detail",
              "text-color": "white",
              color: "red-lp-100",
              iconLeft: "download-white",
              "custom-class": "px-12",
              onClick: _ctx.downloadDetail
            }, null, 8, ["onClick"])
          ])
        ]),
        _createVNode("div", _hoisted_10, [
          _createVNode(_component_TableV2, {
            columns: _ctx.columns,
            firstRowHeader: _ctx.firstRowHeaderColumns,
            secondRowHeader: _ctx.secondRowHeaderColumns,
            data: _ctx.dataCourierPartner,
            pinnedSubstractHeight: "170px",
            isShowPagination: false,
            borderTop: false,
            secondHeaders: "",
            isNoPaddingBottom: ""
          }, null, 8, ["columns", "firstRowHeader", "secondRowHeader", "data"])
        ]),
        _createVNode(_component_Accordion, {
          label: "Pengiriman Berhasil",
          class: "text-12px xxl:text-14px mb-5",
          defaultOpened: "",
          bgColor: "bg-gray-lp-700",
          borderHeader: "border-none",
          mainBorderEdge: "border-none",
          roundedHeader: "rounded-md"
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_11, [
              _createVNode(_component_TableV2, {
                class: "my-2",
                columns: _ctx.columnSuccessful,
                data: _ctx.successfulDelivery,
                isShowPagination: false,
                isNoPaddingBottom: "",
                borderBottom: false,
                borderTop: false,
                iconColor: "green-lp-100"
              }, null, 8, ["columns", "data"])
            ]),
            (_ctx.successfulDelivery.length === 0)
              ? (_openBlock(), _createBlock(_component_ErrorMessage, {
                  key: 0,
                  errorType: "notfound",
                  customClass: "h-auto mt-16"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Accordion, {
          label: "Pengiriman Gagal",
          class: "text-12px xxl:text-14px mb-5",
          defaultOpened: "",
          bgColor: "bg-gray-lp-700",
          borderHeader: "border-none",
          mainBorderEdge: "border-none",
          roundedHeader: "rounded-md"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TableV2, {
              class: "my-2",
              columns: _ctx.columnFailed,
              data: _ctx.failedDelivery,
              isShowPagination: false,
              isNoPaddingBottom: "",
              borderBottom: false,
              borderTop: false,
              iconColor: "green-lp-100"
            }, null, 8, ["columns", "data"]),
            (_ctx.failedDelivery.length === 0)
              ? (_openBlock(), _createBlock(_component_ErrorMessage, {
                  key: 0,
                  errorType: "notfound",
                  customClass: "h-auto mt-16"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.type === 'courier')
          ? (_openBlock(), _createBlock(_component_Accordion, {
              key: 0,
              label: "Dalam Pengiriman",
              class: "text-12px xxl:text-14px mb-5",
              defaultOpened: "",
              bgColor: "bg-gray-lp-700",
              borderHeader: "border-none",
              mainBorderEdge: "border-none",
              roundedHeader: "rounded-md"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TableV2, {
                  class: "my-2",
                  columns: _ctx.columnOnDelivery,
                  data: _ctx.onDelivery,
                  isShowPagination: false,
                  isNoPaddingBottom: "",
                  borderBottom: false,
                  borderTop: false
                }, null, 8, ["columns", "data"]),
                (_ctx.onDelivery.length === 0)
                  ? (_openBlock(), _createBlock(_component_ErrorMessage, {
                      key: 0,
                      errorType: "notfound",
                      customClass: "h-auto mt-16"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.popupSetorHarianModelValue)
      ? (_openBlock(), _createBlock(_component_PopupSetorHarian, {
          key: 0,
          modelValue: _ctx.popupSetorHarianModelValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.popupSetorHarianModelValue = $event)),
          detailDataCourier: _ctx.detailDataCourier,
          isPicShuttle: _ctx.type === 'pic',
          type: _ctx.type
        }, null, 8, ["modelValue", "detailDataCourier", "isPicShuttle", "type"]))
      : _createCommentVNode("", true),
    _createVNode(_component_WidgetDownload)
  ], 64))
}