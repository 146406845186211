
  import { Options, Vue } from "vue-class-component";
  import CodReportReconcileV1 from "../../cod-delivery/cod-report-reconcile/index.vue";
  import CodReportReconcileV2 from "../../cod-delivery-v2/cod-report-reconcile/index.vue";
  @Options({
    components: {
      CodReportReconcileV1,
      CodReportReconcileV2,
    },
  })
  export default class ConfigCodCourier extends Vue {
    get configCodReconcile() {
      return "v1";
    }
  
    get renderedPage(): string {
      return this.configCodReconcile === "v2" ? "CodReportReconcileV2" : "CodReportReconcileV1";
    }
  }
  