import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed flex top-0 right-0 pt-12 px-10 text-gray-lp-800 text-12px font-normal italic" }
const _hoisted_2 = { class: "flex flex-col overflow-hidden pt-4 w-full bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumb, { class: "my-4" }, {
      "additional-label": _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_Refresh, { class: "text-red-lp-300 mr-2" }),
          _createTextVNode(" Sinkronisasi Data Terakhir " + _toDisplayString(_ctx.lastSycnCODDashboard), 1)
        ])
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Tabs, {
        modelValue: _ctx.activeMenu,
        onChange: _ctx.onChangeTabs,
        data: _ctx.tabs,
        rounded: false,
        marginBottom: _ctx.activeMenu === 'all-stt' ? 4 : 0,
        titleSize: 16,
        gap: 10
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["modelValue", "onChange", "data", "marginBottom"])
    ])
  ], 64))
}