import {
  CourierPartnerCodDashboard,
  ListCourierPartnerCodDashboard,
} from "@/domain/entities/Dashboard";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";

export class ReconcileCodMapper {
  convertListPICShuttleFromApi(
    res: AxiosResponse
  ): ListCourierPartnerCodDashboard {
    const { data } = res;

    const dataList = !data.data
      ? []
      : data.data.map(
          (item: any) =>
            new CourierPartnerCodDashboard({
              courierId: item.delivery_pic_phone || item.delivery_driver_phone,
              courierName: item.delivery_pic_name || item.delivery_driver_name,
              courierPhone: item.delivery_pic_phone || item.delivery_driver_phone,
              partnerId: item.delivery_partner_id,
              partnerName: item.delivery_partner_name,
              partnerPhone: item.delivery_partner_phone,
              partnerContactPhone: item.delivery_partner_contact_phone,
              totalStt: item.total_stt,
              totalSttPodSameDay: item.total_stt_pod_same_day,
              totalSttPodDifferentDay: item.total_stt_pod_different_day,
              totalCollected: item.total_collected,
              totalOutstanding: item.total_outstanding,
              totalSttDexCollected: item.total_stt_dex_collected,
              totalSttDexOutstanding: item.total_stt_dex_outstanding,
              totalSttCodrejCollected: item.total_stt_codrej_collected,
              totalSttCodrejOutstanding: item.total_stt_codrej_outstanding,
              totalSttDel: item.total_stt_del,
            })
        );

    return new ListCourierPartnerCodDashboard({
      pagination: new Pagination(data.meta.page, data.meta.limit),
      data: dataList,
    });
  }
}

