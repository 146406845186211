
import { Options, Vue } from "vue-class-component";
import DetailCourierV1 from "../../cod-delivery/components/detail.vue";
import DetailCourierV2 from "../../cod-delivery-v2/components/detail.vue";
@Options({
  components: {
    DetailCourierV1,
    DetailCourierV2,
  },
})
export default class ConfigCodDelivery extends Vue {
  get configCodReconcile() {
    return "v2";
  }

  get renderedPage(): string {
    return this.configCodReconcile === "v2"
      ? "DetailCourierV2"
      : "DetailCourierV1";
  }
}
