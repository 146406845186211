
import { Options, Vue } from "vue-class-component";
import CodTabsCourierV1 from "../../cod-delivery/cod-reconcile/tabs/cod-courier.vue";
import CodTabsCourierV2 from "../../cod-delivery-v2/cod-reconcile/tabs/cod-courier.vue";
@Options({
  components: {
    CodTabsCourierV1,
    CodTabsCourierV2,
  },
})
export default class ConfigCodCourier extends Vue {
  get configCodReconcile() {
    return "v2";
  }

  get renderedPage(): string {
    return this.configCodReconcile === "v2" ? "CodTabsCourierV2" : "CodTabsCourierV1";
  }
}
