import { QueryParamsEntities } from "./MainApp";

export class ListPICShuttledRequest {
  filterDate = "";
  page = 1;
  limit = 10;
  cityCode = "";
  search = "";
  sttCodStatus = "";
  deliveryCollectedStatus = "";

  constructor(fields?: Partial<ListPICShuttledRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
