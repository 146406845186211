
import { formatPriceRP } from "@/app/infrastructures/misc/Utils";
import {
  STTDeposit,
  SummaryCourierPartnerCodDashboard,
} from "@/domain/entities/Dashboard";
import { prop, Vue } from "vue-class-component";

class Props {
  data = prop<SummaryCourierPartnerCodDashboard>({
    default: new SummaryCourierPartnerCodDashboard(),
    type: SummaryCourierPartnerCodDashboard,
  });
}

export default class SummaryCodCourier extends Vue.with(Props) {
  formatPrice(number: any) {
    return formatPriceRP(number);
  }

  get sttDeposit() {
    return this.data.sttDeposit.reduce(
      (a: any, b: any) => {
        return {
          pending: a.pending + b.pending,
          pendingPieces: a.pendingPieces + b.pendingPieces,
        };
      },
      {
        pending: 0,
        pendingPieces: 0,
      }
    );
  }

  get sttInDelivery() {
    return this.data.sttInDelivery.reduce(
      (a: any, b: any) => {
        return {
          count: a.count + b.count,
          pieces: a.pieces + b.pieces,
        };
      },
      {
        count: 0,
        pieces: 0,
      }
    );
  }

  get sttDepositDex() {
    const result = this.data.sttDeposit.find(
      (key) => key.status.toLowerCase() === "dex"
    );
    return result
      ? result
      : {
          status: "dex",
          collected: 0,
          collectedPieces: 0,
          pending: 0,
          pendingPieces: 0,
        };
  }

  get sttDepositCodRej() {
    const result = this.data.sttDeposit.find(
      (key) => key.status.toLowerCase() === "codrej"
    );
    return result
      ? result
      : {
          status: "dex",
          collected: 0,
          collectedPieces: 0,
          pending: 0,
          pendingPieces: 0,
        };
  }

  get sttInDeliveryDel() {
    const result = this.data.sttInDelivery.find(
      (key) => key.status.toLowerCase() === "del"
    );
    return result
      ? result
      : {
          status: "del",
          count: 0,
          pieces: 0,
        };
  }

  get sttInDeliveryProcess() {
    return this.data.sttInDelivery
      .filter(
        (key) =>
          key.status.toLowerCase() === "pod" ||
          key.status.toLowerCase() === "codrej" ||
          key.status.toLowerCase() === "dex"
      )
      .reduce(
        (a: any, b: any) => {
          return {
            count: a.count + b.count,
            pieces: a.pieces + b.pieces,
          };
        },
        {
          count: 0,
          pieces: 0,
        }
      );
  }
}
