/* eslint-disable @typescript-eslint/camelcase */
import store from "@/store";
import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { CodReconcilePresenter } from "../presenters/CodReconcilePresenter";
import {
  CodDashboardDetailCourier,
  CourierDetail,
  CourierDetailApiRequest,
  CourierDetailList,
  CourierDetailPendingReconcile,
  CourierSummary,
  DashboardDeliveryCourierDetail,
} from "@/domain/entities/CodReconcile";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "cod_reconcile",
})
class CodReconcileStore extends VuexModule {
  public isLoading = true;
  public courierSummary = new CourierSummary();
  public courierDetailList = new CourierDetailList();
  public courierDetailListPerStatus = new CourierDetailList();
  public courierDetailListSuccess: CourierDetail[] = [];
  public courierDetailListFailed: CourierDetail[] = [];
  public courierDetailListOnProgress: CourierDetail[] = [];
  public courierDetailSummary = new CodDashboardDetailCourier();
  public dashboardDeliveryCourierDetail = new DashboardDeliveryCourierDetail();

  @Action
  getCourierSummaryDetailV2(
    params: CourierDetailApiRequest
  ): Promise<CourierSummary> {
    const presenter = container.resolve(CodReconcilePresenter);
    return presenter.getCourierSummaryDetailV2(params);
  }

  @Action
  getCourierDetail(payload: CourierDetailApiRequest) {
    const presenter = container.resolve(CodReconcilePresenter);

    return new Promise((resolve, reject) => {
      this.setLoading(true);
      MainAppController.showLoading();
      presenter
        .getCourierDetail(payload)
        .then((res) => {
          const newRes = new CourierDetailList({
            data: res.data.filter(e => e.sttCollectionStatus !== "Sudah Setor")
          })
          this.setCourierDetail(newRes);
          resolve(res);
        })
        .catch((err) => {
          const message = err.response.data.message;
          MainAppController.showErrorMessage(
            typeof message === "string"
              ? new ErrorMessageEntities({
                  title: "Gagal Mengambil Detail Kurir",
                  message,
                })
              : parsingErrorResponse(err)
          );
          reject(err);
        })
        .finally(() => {
          this.setLoading(false);
          MainAppController.closeLoading();
        });
    });
  }

  @Action
  getCourierDetailPerStatus(payload: CourierDetailApiRequest) {
    const presenter = container.resolve(CodReconcilePresenter);

    return new Promise((resolve, reject) => {
      presenter
        .getCourierDetail(payload)
        .then(res => {
          if (payload.status === "success") {
            this.setCourierDetailListSuccess(res.data);
          }
          if (payload.status === "failed") {
            this.setCourierDetailListFailed(res.data);
          }
          if (payload.status === "onprogress") {
            this.setCourierDetailListOnProgress(res.data);
          }
          resolve(res);
        })
        .catch(err => {
          const message = err.response.data.message;
          MainAppController.showErrorMessage(
            typeof message === "string"
              ? new ErrorMessageEntities({
                  title: "Gagal Mengambil Detail Kurir",
                  message
                })
              : parsingErrorResponse(err, "Gagal Mengambil Detail Kurir")
          );
          reject(err);
        })
    });
  }

  @Action
  getCourierDetailSummary(payload: CourierDetailApiRequest) {
    const presenter = container.resolve(CodReconcilePresenter);

    return new Promise((resolve, reject) => {
      this.setLoading(true);
      MainAppController.showLoading();
      presenter
        .getCourierDetailSummary(payload)
        .then(res => {
          this.setCourierDetailSummary(res);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          this.setLoading(false);
          MainAppController.closeLoading();
        });
    });
  }
  
  @Action
  getDashboardDeliveryCourierDetail(phone: string) {
    const presenter = container.resolve(CodReconcilePresenter);

    return new Promise((resolve, reject) => {
      presenter
        .getDashboardDeliveryCourierDetail(phone)
        .then(res => {
          this.setDashboardDeliveryCourierDetail(res);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @Action
  getCourierDetailV2(
    params: CourierDetailApiRequest
  ): Promise<CourierDetail[]> {
    const presenter = container.resolve(CodReconcilePresenter);
    return presenter.getCourierDetailV2(params);
  }

  @Action
  getDetailCourierPendingReconcile(
    params: CourierDetailApiRequest
  ): Promise<CourierDetailPendingReconcile[]> {
    const presenter = container.resolve(CodReconcilePresenter);
    return presenter.getDetailCourierPendingReconcile(params);
  }

  @Mutation
  setLoading(data: boolean) {
    this.isLoading = data;
  }

  @Mutation
  setCourierSummary(data: CourierSummary) {
    this.courierSummary = data;
  }

  @Mutation
  setCourierDetail(data: CourierDetailList) {
    this.courierDetailList = data;
  }

  @Mutation
  setCourierDetailListSuccess(data: CourierDetail[]) {
    this.courierDetailListSuccess = data;
  }

  @Mutation
  setCourierDetailListFailed(data: CourierDetail[]) {
    this.courierDetailListFailed = data;
  }

  @Mutation
  setCourierDetailListOnProgress(data: CourierDetail[]) {
    this.courierDetailListOnProgress = data;
  }

  @Mutation
  setCourierDetailSummary(data: CodDashboardDetailCourier) {
    this.courierDetailSummary = data;
  }

  @Mutation
  setDashboardDeliveryCourierDetail(data: DashboardDeliveryCourierDetail) {
    this.dashboardDeliveryCourierDetail = data;
  }
}

export const CodReconcileController = getModule(CodReconcileStore);
