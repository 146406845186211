import { ReconcileCodRepositoryInterface } from "@/data/persistences/repositories/contracts/ReconcileCodRepositoryInterface";
import { ReconcileCodMapper } from "@/data/persistences/mappers/ReconcileCodMapper";
import { ReconcileCodEndpoints } from "../../endpoints/hydra/ReconcileCodEndpoints";
import ApiService from "../../services/ApiService";
import { ListPICShuttledRequest } from "@/domain/entities/ReconcileCod";
import { ListCourierPartnerCodDashboard } from "@/domain/entities/Dashboard";

export class ReconcileCodApiRepository
  implements ReconcileCodRepositoryInterface {
  private service: ApiService;
  private mapper: ReconcileCodMapper;
  private endpoints: ReconcileCodEndpoints;

  constructor(
    service: ApiService,
    mapper: ReconcileCodMapper,
    endpoints: ReconcileCodEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListPICShuttle(
    params: ListPICShuttledRequest
  ): Promise<ListCourierPartnerCodDashboard> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getListPICShuttle(params)
    );

    return this.mapper.convertListPICShuttleFromApi(resp);
  }
}
